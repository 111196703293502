import React, { useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from "react-router-dom";
import styles from './GuestSidebar.module.scss'
import MenuIcon from '@mui/icons-material/Menu';
import { observer } from "mobx-react-lite";
import { useAppStore } from '../../../stores/AppStore';
import { GuestMenuBarItems } from '../../../routes/route-option-GuestMenuBar';
import { GetIconByName } from '../../common/GetIconByName';
import logo from './../../../assets/images/logo/logo.png';
import Divider from '../../common/Divider';
import { useTranslation } from 'react-i18next';
import IOSSwitch from '../../common/IOSSwitch';
import { ReactComponent as IconVN } from '../../../assets/images/flags/vn.svg';
import { ReactComponent as IconUSA } from '../../../assets/images/flags/usa.svg';

export const GuestSidebar = observer(() => {
    const { authStore } = useAppStore();

    // const [logo_no_words, locoH, logoV] = logoUrl.split('_split_');
    const [stateSidebarItems, ] = useState(GuestMenuBarItems); //tạo state toggle và cho nội dung của nó ban đầu là mảng AdminMenuRoute
    const [stateBoolSidebar, setStateBoolSidebar] = useState(false); // boolean
    const toggleSidebar = () => {
        setStateBoolSidebar(!stateBoolSidebar)
    }

    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const [checked, setChecked] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked; // Get the new value (true/false)
        setChecked(value); // Get the new value (true/false)
        const lng = value ? 'English' : 'Vietnam';
        switch (lng) {
            case 'Vietnam':
                i18n.changeLanguage('Vietnam');
                authStore.setLanguage('Vietnam');
                break;
            case 'English':
                i18n.changeLanguage('English');
                authStore.setLanguage('English');
                break;

            default:
                break;
        }
    };
    const navigate = useNavigate(); //hook dùng để chuyển trang web

    const select_MenuItem = (title: string) => {
        console.log('Selected title:', title);

        switch (title) {
            case 'Products':
                navigate("/productgroup", { replace: true });
                break;
            case 'Gallery':
                navigate("/gallery", { replace: true });
                break;
            case 'Catalogs':
                navigate("/catalog", { replace: true });
                break;
            case 'Contact':
                navigate("/contact", { replace: true });
                break;
            case 'Sign In':
                navigate("/signin", { replace: true });
                break;
            case 'Sign Out':
                authStore.storeLogout(); // xóa token khỏi BE (DB) và FE (local storage)
                navigate("/productgroup", { replace: true });
                break;
            case 'Change Password':
                navigate("/changepassword", { replace: true });
                break;
            case 'Update Phone':
                navigate("/updatephone", { replace: true });
                break;
            default:
                break;
        }
    }
    return (
        // stateBoolSidebar === false ? 
        <>
            <div className={clsx(styles.BoxMenubar, { [styles.moveRightBoxMenubar]: !stateBoolSidebar }, { [styles.moveLeftBoxMenubar]: stateBoolSidebar })}>
                <MenuIcon className={clsx(styles.iconMenu)} onClick={toggleSidebar} />
            </div>
            <div className={clsx(styles.component_Sidebar, { [styles.moveLeftSidebar]: !stateBoolSidebar }, { [styles.moveRightSidebar]: stateBoolSidebar })}>

                <div className={clsx(styles.top)}>
                    <div className={clsx(styles.rowTop)}>
                        <div className={clsx(styles.boxLogo)}>
                            <img src={logo} alt="logo" />
                        </div>
                        <div className={clsx(styles.boxIconMenu)}>
                            <MenuIcon className={clsx(styles.iconMenu)} onClick={toggleSidebar} />
                        </div>
                    </div>
                    <Divider width={100} />
                    {
                        authStore.isAuthenticated &&
                        <>
                            <div className={clsx(styles.rowName)}>
                                {GetIconByName('AccountCircleIcon', '2rem')}
                                <p className={clsx(styles.name)}>{authStore.user?.name}</p>
                            </div>
                            <Divider width={100} />
                        </>
                    }
                    <div className={clsx(styles.listItemSideBar)}>

                        {stateSidebarItems && stateSidebarItems.map((obj) => {
                            return (
                                <p key={obj.id} className={clsx(styles.itemSideBar)} onClick={() => select_MenuItem(obj.title)}>{t(obj.title)}</p>
                            );
                        })}
                    </div>
                </div>
                <div className={clsx(styles.bottom)}>
                    <Divider width={100} />
                    <p className={clsx(styles.itemSideBar)} onClick={() => select_MenuItem('Settings')}>{t('Settings')}</p>
                    {
                        authStore.isAuthenticated === false ?
                            <p className={clsx(styles.itemSideBar)} onClick={() => select_MenuItem('Sign In')}>{t('Sign In')}</p>
                            :
                            <>
                                <p className={clsx(styles.itemSideBar)} onClick={() => select_MenuItem('Change Password')}>{t('Change Password')}</p>
                                <p className={clsx(styles.itemSideBar)} onClick={() => select_MenuItem('Update Phone')}>{t('Update Phone')}</p>
                                <p className={clsx(styles.itemSideBar)} onClick={() => select_MenuItem('Sign Out')}>{t('Sign Out')}</p>
                            </>

                    }
                    <div className={clsx(styles.language)}>
                        <IconVN style={{ width: '35px', height: '35px' }} />
                        <IOSSwitch checked={checked} onChange={handleChange} />
                        <IconUSA style={{ width: '35px', height: '35px' }} />
                    </div>
                </div>
            </div>
            <div className={clsx(styles.backShadow, { [styles.appear]: stateBoolSidebar })} onClick={toggleSidebar}>
            </div>
        </>
    );
})