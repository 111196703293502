import clsx from 'clsx';
import styles from './ChangePassword.module.scss';
import { observer } from "mobx-react-lite";
import { useAppStore } from '../../stores/AppStore';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { validatePassword } from '../../utils/validation';
import Btn from '../../components/common/Btn';
import { useTranslation } from 'react-i18next';
import { useMutation } from "@tanstack/react-query";
import { changePassword } from '../../services';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import { UpdatePasswordDto } from '../../types/UpdatePasswordDto';



const ChangePassword = observer(() => {
    const { authStore } = useAppStore();
    const { t } = useTranslation();
    const passwordValidation = Yup.string()
    .test('password-validation', t('Password must have at least 8 characters, maximum 30 characters, including lowercase, uppercase, special character, and number.'), value => validatePassword(value))
    .required(t('New password is required'));

  // Validation schema using the `t` function for translations
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required(t('Old password is required')),
    newPassword: passwordValidation,
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('newPassword')], t('Passwords must match'))
      .required(t('Please retype your password to confirm')),
  });

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onChange', // Validate on change
    });

    const mutation = useMutation({
        mutationFn: changePassword,
        onSuccess: (response: any) => {
            console.log('response: ', response);
            if(response.statusCode === 200){
                alert(t(response.message));
                reset();
            }
            else {
                alert(t(response.message));
            }
        },
        onError: (error: any) => {
            console.error('Error during mutation:', error);
            alert(t('changePassword.error'));
        }
     });
     console.log("authStore.user?.phone", authStore.user?.phone);
    const onSubmit = (data: any) => {
        if (authStore.user?.phone === undefined) {
            // Handle the case where phone is undefined
            // alert("Bạn cần phải sign in lại. Xin cảm ơn")
            authStore.storeLogout();
            window.location.href = '/signin';
            return;
        }
        // Handle form submission
        const obj : UpdatePasswordDto = {
            oldPass: data.oldPassword,
            newPass: data.newPassword,
            retypePass: data.passwordConfirm,
            phone: authStore.user?.phone,
        }
        mutation.mutate(obj);

    };

    const onReset = () => {
        reset();
    };
   const [stateBoolShowPassOld, setStateBoolShowPassOld] = useState(false); // boolean
   const [stateBoolShowPassNew, setStateBoolShowPassNew] = useState(false); // boolean
   const [stateBoolShowPassRetype, setStateBoolShowPassRetype] = useState(false); // boolean

    // askfjJHF7364&^^
    return (
        <div className={clsx(styles.ChangePassword)}>
            <div className={clsx(styles.container)}>
                <p className={clsx(styles.title)}>{t('Change Password')}</p>

                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* Old Password */}
                    <div className={clsx(styles.row)}>
                        <label>{t('Old password')}: </label>
                        <div className={clsx(styles.inputWrapper)}>
                            <input type={stateBoolShowPassOld ? 'text' : 'password'} {...register('oldPassword')} />
                            {  stateBoolShowPassOld === true ?
                                <VisibilityOffIcon className={clsx(styles.eye)} onClick={()=>setStateBoolShowPassOld(false)}/>
                                :
                                <VisibilityIcon className={clsx(styles.eye)} onClick={()=>setStateBoolShowPassOld(true)}/>
                            }
                        
                        </div>
                    </div>
                    <div className={clsx(styles.row, styles.error)}>
                        <label></label>
                        <span>{errors.oldPassword?.message}</span>
                        <label></label>
                    </div>

                    {/* New Password */}
                    <div className={clsx(styles.row)}>
                        <label>{t('New password')}: </label>
                        <div className={clsx(styles.inputWrapper)}>
                            <input type={stateBoolShowPassNew ? 'text' : 'password'} {...register('newPassword')} />
                            {  stateBoolShowPassNew === true ?
                                <VisibilityOffIcon className={clsx(styles.eye)} onClick={()=>setStateBoolShowPassNew(false)}/>
                                :
                                <VisibilityIcon className={clsx(styles.eye)} onClick={()=>setStateBoolShowPassNew(true)}/>
                            }
                        </div>
                    </div>
                    <div className={clsx(styles.row, styles.error)}>
                        <label></label>
                        <span>{errors.newPassword?.message}</span>
                        <label></label>
                    </div>

                    {/* Confirm Password */}
                    <div className={clsx(styles.row)}>
                        <label>{t('Password confirmed')}:</label>
                        <div className={clsx(styles.inputWrapper)}>
                            <input type={stateBoolShowPassRetype ? 'text' : 'password'} {...register('passwordConfirm')} />
                            {  stateBoolShowPassRetype === true ?
                                <VisibilityOffIcon className={clsx(styles.eye)} onClick={()=>setStateBoolShowPassRetype(false)}/>
                                :
                                <VisibilityIcon className={clsx(styles.eye)} onClick={()=>setStateBoolShowPassRetype(true)}/>
                            }
                        </div>
                    </div>
                    <div className={clsx(styles.row, styles.error)}>
                        <label></label>
                        <span>{errors.passwordConfirm?.message}</span>
                        <label></label>
                    </div>

                    {/* Submit Button */}
                    <div className={clsx(styles.btnWrapper)}>
                        <span>
                            <Btn 
                                colorText="white" 
                                colorBg="warning" 
                                hoverColorBg="warning" 
                                text={t('Submit')} 
                            />
                        </span>
                        <span onClick={onReset}>
                            <Btn 
                                colorText="white" 
                                colorBg="secondary" 
                                hoverColorBg="secondary" 
                                text={t('Reset')} 
                            />
                        </span>
                    </div>
                </form>
            </div>
        </div>
    );
});
export default ChangePassword;
