import React, { useState, CSSProperties } from 'react';
import clsx from 'clsx'; // Ensure clsx is imported

// ButtonProps interface with optional className
interface ButtonProps {
    colorText?: string;
    colorBg?: string;
    hoverColorBg?: string;
    text: string;
    onClick?: () => void; // Optional onClick
    className?: string; // Add className prop
}

const Btn: React.FC<ButtonProps> = ({ colorText, colorBg, hoverColorBg, text, onClick, className }) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isActive, setIsActive] = useState(false);

    // Predefined color codes
    const colors: { [key: string]: string } = {
        primary: '#007bff',
        secondary: '#6c757d',
        light_secondary: '#b1b1b1',
        success: '#28a745',
        danger: '#dc3545',
        warning: '#ffc107',
        info: '#17a2b8',
        light: '#f8f9fa',
        dark: '#343a40',
    };

    const hoverColors: { [key: string]: string } = {
        primary: '#0056b3',
        secondary: '#5a6268',
        light_secondary: '#b1b1b1',
        success: '#218838',
        danger: '#c82333',
        warning: '#e0a800',
        info: '#117a8b',
        light: '#e2e6ea',
        dark: '#23272b',
    };

    const bgColor = colorBg ? colors[colorBg] : '#920a41';
    const hoverBgColor = hoverColorBg ? hoverColors[hoverColorBg] : bgColor;

    const styles: CSSProperties = {
        backgroundColor: isHovered ? hoverBgColor : bgColor,
        color: colorText || 'white',
        padding: '0.5rem 1rem',
        textAlign: 'center',
        textDecoration: 'none',
        display: 'inline-block',
        fontSize: '16px',
        margin: '4px 2px',
        cursor: 'pointer',
        borderRadius: '7px',
        border: 'none',
        boxShadow: isActive ? 'none' : '0 0 10px rgba(152, 152, 152, 0.893)',
        transition: 'box-shadow 0.4s ease, background-color 0.4s ease',
    };

    return (
        <button
            style={styles}
            className={clsx(className)} // Apply className using clsx
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onMouseDown={() => setIsActive(true)}
            onMouseUp={() => setIsActive(false)}
            onMouseOut={() => setIsActive(false)} // In case the mouse leaves the button while pressed
            onClick={onClick} // Optional onClick
        >
            {text}
        </button>
    );
};

export default Btn;
