import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

interface MenuItemProps {
    label: string;
    icon?: React.ReactNode;
    iconFront?: boolean; // Optional prop to position icon in front
    iconBack?: boolean;  // Optional prop to position icon behind
}

interface MenuCircleProps {
    menuItems: MenuItemProps[];
    onMenuItemClick: (label: string) => void; // Callback function to pass selected item to parent
}

export default function MenuCircle({ menuItems, onMenuItemClick }: MenuCircleProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (label: string) => {
        onMenuItemClick(label); // Pass the selected item's label to the parent
        handleClose();
    };

    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                slotProps={{
                    paper: {
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                            '&::before': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                top: 0,
                                right: 14,
                                width: 10,
                                height: 10,
                                bgcolor: 'background.paper',
                                transform: 'translateY(-50%) rotate(45deg)',
                                zIndex: 0,
                            },
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {menuItems.map((item, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => handleMenuItemClick(item.label)}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }} // Ensure horizontal alignment
                    >
                        {item.icon && item.iconFront && (
                            <span style={{ marginRight: '8px', display: 'flex', alignItems: 'center' }}>{item.icon}</span> // Center the icon
                        )}
                        <span style={{ display: 'flex', alignItems: 'center' }}>{t(item.label)}</span> 
                        {item.icon && item.iconBack && (
                            <span style={{ marginLeft: '8px', display: 'flex', alignItems: 'center' }}>{item.icon}</span> // Center the second icon
                        )}
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    );
}
