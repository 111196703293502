import { beadboard, moulding_gallery, nucedar } from "..";
import { GalleryImage, GalleryType, ImageCategory } from "../../../types/GalleryImage";

export const dataImages: GalleryImage[] = [
    {
        id: 1,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.Bedroom,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 2,
        url: beadboard,
        href: '',
        category: ImageCategory.Terrace,
        galleryType: GalleryType.Moulding
    },
    {
        id: 3,
        url: beadboard,
        href: '',
        category: ImageCategory.HallwaysandFoyers,
        galleryType: GalleryType.Moulding
    },
    {
        id: 4,
        url: beadboard,
        href: '',
        category: ImageCategory.Bedroom,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 5,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.Bedroom,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 6,
        url: nucedar,
        href: '',
        category: ImageCategory.ArtStudio,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 7,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.LivingRoom,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 8,
        url: nucedar,
        href: '',
        category: ImageCategory.BilliardRoom,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 9,
        url: nucedar,
        href: '',
        category: ImageCategory.Kitchen,
        galleryType: GalleryType.Moulding
    },
    {
        id: 10,
        url: nucedar,
        href: '',
        category: ImageCategory.HallwaysandFoyers,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 11,
        url: beadboard,
        href: '',
        category: ImageCategory.DiningRoom,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 12,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.LivingRoom,
        galleryType: GalleryType.Moulding
    },
    {
        id: 13,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.Pool,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 14,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.LaundryRoom,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 15,
        url: moulding_gallery,
        href: 'https://vr.justeasy.cn/view/u13o691439i70d75-1695801430.html',
        category: ImageCategory.LivingRoom,
        galleryType: GalleryType.Moulding
    },
    {
        id: 16,
        url: moulding_gallery,
        href: 'https://vr.justeasy.cn/view/u13o691439i70d75-1695801430.html',
        category: ImageCategory.Closet,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 17,
        url: nucedar,
        href: 'https://vr.justeasy.cn/view/u13o691439i70d75-1695801430.html',
        category: ImageCategory.HallwaysandFoyers,
        galleryType: GalleryType.Moulding
    },
    {
        id: 18,
        url: beadboard,
        href: 'https://vr.justeasy.cn/view/u13o691439i70d75-1695801430.html',
        category: ImageCategory.HallwaysandFoyers,
        galleryType: GalleryType.Moulding
    },
    {
        id: 19,
        url: beadboard,
        href: 'https://vr.justeasy.cn/view/u13o691439i70d75-1695801430.html',
        category: ImageCategory.Terrace,
        galleryType: GalleryType.Moulding
    },
    {
        id: 20,
        url: '',
        href: 'https://vr.justeasy.cn/view/u13o691439i70d75-1695801430.html',
        category: ImageCategory.LaundryRoom,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 21,
        url: moulding_gallery,
        href: 'https://vr.justeasy.cn/view/u13o691439i70d75-1695801430.html',
        category: ImageCategory.Lounge,
        galleryType: GalleryType.Moulding
    },
    {
        id: 22,
        url: beadboard,
        href: 'https://vr.justeasy.cn/view/u13o691439i70d75-1695801430.html',
        category: ImageCategory.Pool,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 23,
        url: nucedar,
        href: 'https://vr.justeasy.cn/view/u13o691439i70d75-1695801430.html',
        category: ImageCategory.HallwaysandFoyers,
        galleryType: GalleryType.Moulding
    },
    {
        id: 24,
        url: nucedar,
        href: 'https://vr.justeasy.cn/view/u13o691439i70d75-1695801430.html',
        category: ImageCategory.Pool,
        galleryType: GalleryType.Moulding
    },
    {
        id: 25,
        url: beadboard,
        href: 'https://vr.justeasy.cn/view/u13o691439i70d75-1695801430.html',
        category: ImageCategory.Office,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 26,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.MediaRoom,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 27,
        url: beadboard,
        href: '',
        category: ImageCategory.Kitchen,
        galleryType: GalleryType.Moulding
    },
    {
        id: 28,
        url: beadboard,
        href: '',
        category: ImageCategory.Closet,
        galleryType: GalleryType.Moulding
    },
    {
        id: 29,
        url: beadboard,
        href: '',
        category: ImageCategory.BilliardRoom,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 30,
        url: nucedar,
        href: '',
        category: ImageCategory.DiningRoom,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 31,
        url: beadboard,
        href: '',
        category: ImageCategory.DiningRoom,
        galleryType: GalleryType.Moulding
    },
    {
        id: 32,
        url: nucedar,
        href: '',
        category: ImageCategory.Bedroom,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 33,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.BilliardRoom,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 34,
        url: nucedar,
        href: '',
        category: ImageCategory.Pool,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 35,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.Office,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 36,
        url: beadboard,
        href: '',
        category: ImageCategory.LivingRoom,
        galleryType: GalleryType.Moulding
    },
    {
        id: 37,
        url: beadboard,
        href: '',
        category: ImageCategory.DiningRoom,
        galleryType: GalleryType.Moulding
    },
    {
        id: 38,
        url: beadboard,
        href: '',
        category: ImageCategory.Pool,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 39,
        url: nucedar,
        href: '',
        category: ImageCategory.MediaRoom,
        galleryType: GalleryType.Moulding
    },
    {
        id: 40,
        url: beadboard,
        href: '',
        category: ImageCategory.Lounge,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 41,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.HallwaysandFoyers,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 42,
        url: nucedar,
        href: '',
        category: ImageCategory.MediaRoom,
        galleryType: GalleryType.Moulding
    },
    {
        id: 43,
        url: nucedar,
        href: '',
        category: ImageCategory.HallwaysandFoyers,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 44,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.Lounge,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 45,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.Terrace,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 46,
        url: beadboard,
        href: '',
        category: ImageCategory.WineRoom,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 47,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.Library,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 48,
        url: beadboard,
        href: '',
        category: ImageCategory.Terrace,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 49,
        url: nucedar,
        href: '',
        category: ImageCategory.Closet,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 50,
        url: beadboard,
        href: '',
        category: ImageCategory.Lounge,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 51,
        url: beadboard,
        href: '',
        category: ImageCategory.Terrace,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 52,
        url: beadboard,
        href: '',
        category: ImageCategory.Kitchen,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 53,
        url: nucedar,
        href: '',
        category: ImageCategory.LaundryRoom,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 54,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.WineRoom,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 55,
        url: beadboard,
        href: '',
        category: ImageCategory.Bathroom,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 56,
        url: beadboard,
        href: '',
        category: ImageCategory.Bathroom,
        galleryType: GalleryType.Moulding
    },
    {
        id: 57,
        url: nucedar,
        href: '',
        category: ImageCategory.WineRoom,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 58,
        url: beadboard,
        href: '',
        category: ImageCategory.Office,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 59,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.Bedroom,
        galleryType: GalleryType.Moulding
    },
    {
        id: 60,
        url: nucedar,
        href: '',
        category: ImageCategory.BilliardRoom,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 61,
        url: beadboard,
        href: '',
        category: ImageCategory.Terrace,
        galleryType: GalleryType.Moulding
    },
    {
        id: 62,
        url: nucedar,
        href: '',
        category: ImageCategory.Lounge,
        galleryType: GalleryType.Moulding
    },
    {
        id: 63,
        url: nucedar,
        href: '',
        category: ImageCategory.HallwaysandFoyers,
        galleryType: GalleryType.Moulding
    },
    {
        id: 64,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.BilliardRoom,
        galleryType: GalleryType.Moulding
    },
    {
        id: 65,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.ArtStudio,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 66,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.Lounge,
        galleryType: GalleryType.Moulding
    },
    {
        id: 67,
        url: nucedar,
        href: '',
        category: ImageCategory.Office,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 68,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.ArtStudio,
        galleryType: GalleryType.Moulding
    },
    {
        id: 69,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.Bedroom,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 70,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.Closet,
        galleryType: GalleryType.Moulding
    },
    {
        id: 71,
        url: nucedar,
        href: '',
        category: ImageCategory.Bedroom,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 72,
        url: nucedar,
        href: '',
        category: ImageCategory.WineRoom,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 73,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.ArtStudio,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 74,
        url: beadboard,
        href: '',
        category: ImageCategory.Terrace,
        galleryType: GalleryType.Moulding
    },
    {
        id: 75,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.Bathroom,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 76,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.HallwaysandFoyers,
        galleryType: GalleryType.Moulding
    },
    {
        id: 77,
        url: nucedar,
        href: '',
        category: ImageCategory.Terrace,
        galleryType: GalleryType.Moulding
    },
    {
        id: 78,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.Closet,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 79,
        url: beadboard,
        href: '',
        category: ImageCategory.Closet,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 80,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.HallwaysandFoyers,
        galleryType: GalleryType.Moulding
    },
    {
        id: 81,
        url: nucedar,
        href: '',
        category: ImageCategory.Office,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 82,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.Pool,
        galleryType: GalleryType.Moulding
    },
    {
        id: 83,
        url: beadboard,
        href: '',
        category: ImageCategory.DiningRoom,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 84,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.WineRoom,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 85,
        url: beadboard,
        href: '',
        category: ImageCategory.Terrace,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 86,
        url: beadboard,
        href: '',
        category: ImageCategory.LivingRoom,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 87,
        url: beadboard,
        href: '',
        category: ImageCategory.ArtStudio,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 88,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.ArtStudio,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 89,
        url: nucedar,
        href: '',
        category: ImageCategory.DiningRoom,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 90,
        url: nucedar,
        href: '',
        category: ImageCategory.Kitchen,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 91,
        url: beadboard,
        href: '',
        category: ImageCategory.DiningRoom,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 92,
        url: beadboard,
        href: '',
        category: ImageCategory.Bedroom,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 93,
        url: beadboard,
        href: '',
        category: ImageCategory.Closet,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 94,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.Lounge,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 95,
        url: beadboard,
        href: '',
        category: ImageCategory.Terrace,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 96,
        url: nucedar,
        href: '',
        category: ImageCategory.WineRoom,
        galleryType: GalleryType.Beadboard
    },
    {
        id: 97,
        url: moulding_gallery,
        href: '',
        category: ImageCategory.Bathroom,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 98,
        url: nucedar,
        href: '',
        category: ImageCategory.BilliardRoom,
        galleryType: GalleryType.Nucedar
    },
    {
        id: 99,
        url: beadboard,
        href: '',
        category: ImageCategory.Pool,
        galleryType: GalleryType.Moulding
    },
    {
        id: 100,
        url: nucedar,
        href: '',
        category: ImageCategory.Lounge,
        galleryType: GalleryType.Moulding
    },
]

