import clsx from 'clsx';
import styles from './Catalog.module.scss';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { CatalogPimaAllProducts, DoorCompositePima2024} from '../../assets/pdf';
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';

const Catalog = observer(() => {
    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    // Update screen width on resize
    useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const catalogs = [
        { imgName: 'CatalogPimaAllProducts', name: 'Catalog Pima All Products', url: CatalogPimaAllProducts, pdfName: "CatalogPimaAllProducts"  },
        { imgName: 'DoorCompositePima2024', name: 'Door Composite Pima 2024', url: DoorCompositePima2024, pdfName: "DoorCompositePima2024"  },
    ];

    const openPDFInCatalogBook = (pdfName: string) => {
        // case 2 mở ra trang CatalogBook.tsx
        navigate(`/catalog/${pdfName}`, { replace: true });
    };
    const openPDFInNewTab = (url: string) => {
        //case 1: mở thẳng ra pdf
        window.open(url, '_blank'); // Opens the PDF in a new tab
    };
    return (
        <div className={clsx(styles.Catalog)}>
            <p className={clsx(styles.title)}>CATALOGS</p>
            <div className={clsx(styles.catalogList)}>
                {catalogs.map((catalog, index) => (
                    <div key={index} className={clsx(styles.catalogItem)} 
                        onClick={() => screenWidth <= 1200 ? openPDFInNewTab(catalog.url) : openPDFInCatalogBook(catalog.pdfName)}
                    >
                        <img src={`./asset/pdfPreview/${catalog.imgName}.png`} alt="catalog cover" />
                        <p className={clsx(styles.catalogName)}>{t(catalog.name)}</p>
                    </div>
                ))}
            </div>
        </div>
    );
});

export default Catalog;
