import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { observer } from "mobx-react-lite";
import { GetIconByName } from '../../common/GetIconByName';
import styles from './BottomMenuBar.module.scss';
import { useAppStore } from '../../../stores/AppStore';
import { useNavigate } from 'react-router-dom';

const BottomMenuBar = observer(() => {
    const { t } = useTranslation();
    const { bottomMenuBarStore } = useAppStore();
    const selectedBoxFromStore = bottomMenuBarStore.selectedBox;
    const navigate = useNavigate(); 
    // Function to handle box selection
    const handleSelect = (name: string) => {
        bottomMenuBarStore.updateSelectedBox(name);
        switch (name) {
            case 'Products':
                navigate("/productgroup", { replace: true });
                break;
            case 'Gallery':
                navigate("/gallery", { replace: true });
                break;
            case 'Catalogs':
                navigate("/catalog", { replace: true }); 
                break;
            case 'Contact':
                navigate("/contact", { replace: true }); 
                break;
            default:
                break;
        }
    };
    return (
        <div className={clsx(styles.BottomMenuBar)}>
            <div 
                className={clsx(styles.box, selectedBoxFromStore === 'Products' && styles.selected)} 
                onClick={() => handleSelect('Products')} // Set the selected box
            >
               <div className={clsx(styles.icon)}>
                  {GetIconByName('category', 'medium')}
               </div>
               <p className={clsx(styles.name)}>{t('Products')}</p>
            </div>
            <div 
                className={clsx(styles.box, selectedBoxFromStore === 'Gallery' && styles.selected)}
                onClick={() => handleSelect('Gallery')}
            >
               <div className={clsx(styles.icon)}>
                  {GetIconByName('gallery', 'medium')}
               </div>
               <p className={clsx(styles.name)}>{t('Gallery')}</p>
            </div>
            <div 
                className={clsx(styles.box, selectedBoxFromStore === 'Catalogs' && styles.selected)} 
                onClick={() => handleSelect('Catalogs')}
            >
               <div className={clsx(styles.icon)}>
                  {GetIconByName('docs', 'medium')}
               </div>
               <p className={clsx(styles.name)}>{t('Catalogs')}</p>
            </div>
            <div 
                className={clsx(styles.box, selectedBoxFromStore === 'Contact' && styles.selected)} 
                onClick={() => handleSelect('Contact')}
            >
               <div className={clsx(styles.icon)}>
                  {GetIconByName('contact', 'medium')}
               </div>
               <p className={clsx(styles.name)}>{t('Contact')}</p>
            </div>
        </div>
    );
});

export default BottomMenuBar;
