import React, { useContext } from "react";
import { makeAutoObservable } from "mobx";
import { AuthStore } from "./AuthStore";
import { ProductStore } from "./ProductStore";
import { BottomMenuBarStore } from "./BottomMenuBarStore";
import { GalleryStore } from "./GalleryStore";

export class AppStore {
    authStore: AuthStore = new AuthStore();
    productStore: ProductStore = new ProductStore();
    galleryStore: GalleryStore = new GalleryStore();
    bottomMenuBarStore: BottomMenuBarStore = new BottomMenuBarStore();

    constructor() {
        makeAutoObservable(this);
    }
    
}

export const appStore = new AppStore();
export const AppStoreContext = React.createContext(appStore);

export const useAppStore = () => useContext(AppStoreContext);