import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useParams } from "react-router-dom";
import styles from './Gallery.module.scss'
import { observer } from "mobx-react-lite";
import { dataImages } from '../../assets/images/gallery/dataImages';
import { useTranslation } from 'react-i18next';
import { GalleryImage, ImageCategory } from '../../types/GalleryImage';
import { GetIconByName } from '../../components/common/GetIconByName';
import { view360 } from '../../assets/images';

const Gallery = observer(() => {
    const { t } = useTranslation();
    const [checkedCategories, setCheckedCategories] = useState<ImageCategory[]>(Object.values(ImageCategory));
    const [stateIsOpenFilterDialog, setStateIsOpenFilterDialog] = useState<boolean>(false); // mở hay đóng filter dialog
    const [stateBackShadow, setStateBackShadow] = useState<boolean>(false);
    const [stateBackShadow2, setStateBackShadow2] = useState<boolean>(false); // nền cho dialog image

    const [stateIsSelectAll, setStateIsSelectAll] = useState<boolean>(false); // thể hiện việc có select all filter hay là ko, false là ko, true là có
    const [stateArrDataImages_afterFilterGalleryType, setStateArrDataImages_afterFilterGalleryType] = useState<GalleryImage[]>(dataImages);
    const [stateArrDataImages_afterFilterGalleryType_andImageCategory, setStateArrDataImages_afterFilterGalleryType_andImageCategory] = useState<GalleryImage[]>([]);
    const [filteredCount, setFilteredCount] = useState<number>(0);
    const { galleryType } = useParams(); // Extracts `id` from the URL
    const currentGalleryType = galleryType || 'Moulding';
    const handleCloseFilter = () => {

        const data = stateArrDataImages_afterFilterGalleryType.filter((image: GalleryImage) =>
            checkedCategories.includes(image.category)
        );
        setStateArrDataImages_afterFilterGalleryType_andImageCategory(data); // thằng này để render ra sau cùng
        setFilteredCount(data.length);

        setStateIsOpenFilterDialog(false);
        setStateBackShadow(false);
    };
    const handleOpenFilter = () => {
        setStateIsOpenFilterDialog(true);
        setStateBackShadow(true);
    };
    const handleCheckboxChange = (category: ImageCategory) => {
        setCheckedCategories(prev => {
            if (prev.includes(category)) {
                // If already checked, remove it
                return prev.filter(item => item !== category);
            } else {
                // If not checked, add it
                return [...prev, category];
            }
        });
    };

    // Use useEffect to handle the filtering whenever checkedCategories changes
    useEffect(() => {
        const data = stateArrDataImages_afterFilterGalleryType.filter((image: GalleryImage) =>
            checkedCategories.includes(image.category)
        );

        // Update state with the filtered images
        setStateArrDataImages_afterFilterGalleryType_andImageCategory(data);
        setFilteredCount(data.length);
    }, [checkedCategories, stateArrDataImages_afterFilterGalleryType]);



    const clearFilter = () => {
        setStateIsSelectAll(false); // sau khi đã nhấn nút clear all tì ẩn clear all đi và hiện nút select all lên
        setCheckedCategories([]);

    }
    const selectAll = () => {
        setStateIsSelectAll(true); // sau khi đã nhấn nút select all tì ẩn select all đi và hiện nút clear all lên
        if (checkedCategories.length === Object.values(ImageCategory).length) {
            // If all categories are checked, clear all
            setCheckedCategories([]);
        } else {
            // If not all are checked, select all
            setCheckedCategories(Object.values(ImageCategory));
        }
    };

    const unselectedThisCategory = (categoryToRemove: ImageCategory) => {
        setCheckedCategories(prevCategories =>
            prevCategories.filter(category => category !== categoryToRemove)
        );
    };
    const [stateStringUrlOfSelectedImage, setstateStringUrlOfSelectedImage] = useState<string>('');
    const selectImageToSeeBigger = (url: string) => {
        console.log('url: ', url);
        setstateStringUrlOfSelectedImage(url);
        setStateBackShadow2(true);
    }
    const handleCloseDialogImage = () => {

        setstateStringUrlOfSelectedImage('');
        setStateBackShadow2(false);
    }

    useEffect(() => {
        const data = dataImages.filter((image: GalleryImage) => image.galleryType === currentGalleryType);
        setStateArrDataImages_afterFilterGalleryType(data); // thằng này để giữ lại data sau khi filter galleryType
        setStateArrDataImages_afterFilterGalleryType_andImageCategory(data); // thằng này để render ra sau cùng
        setFilteredCount(data.length);
    }, [currentGalleryType]);
    return (
        <div className={clsx(styles.Gallery)}>
            <p className={clsx(styles.title)}>{t('Gallery')} {t(currentGalleryType)}</p>
            <div className={clsx(styles.container)}>
                <div className={clsx(styles.left)}>
                    <div className={clsx(styles.filterListWeb)}>
                        <div className={clsx(styles.topWrapper)}>
                            <p className={clsx(styles.heading)}>{t('Filter')}</p>
                            <p className={clsx(styles.countWebView)}><span>{t('All Images')}: </span>{filteredCount}</p>
                        </div>

                        {
                            stateIsSelectAll === true ?
                                <div className={clsx(styles.row)} onClick={clearFilter}>
                                    <span>{GetIconByName('close')}</span>
                                    <label style={{ fontWeight: 'bold', color: '#0d6efd' }}>{t('Clear all')}</label>
                                </div>
                                :
                                <div className={clsx(styles.row)} onClick={selectAll}>
                                    <span>{GetIconByName('list')}</span>
                                    <label style={{ fontWeight: 'bold', color: '#0d6efd' }}>{t('Select all')}</label>
                                </div>
                        }
                        {Object.values(ImageCategory).map(category => (
                            <div key={category} className={clsx(styles.row)}>
                                <input
                                    type="checkbox"
                                    checked={checkedCategories.includes(category)}
                                    onChange={() => handleCheckboxChange(category)}
                                />
                                <label>{t(category)}</label>
                            </div>
                        ))}
                    </div>
                    <div className={clsx(styles.filterResult)}>
                        <div className={clsx(styles.topRow)}>
                            <div className={clsx(styles.btnIcon)} onClick={handleOpenFilter}>
                                <span>{GetIconByName('filter', '17px', 'small', 'white')}</span>
                                <button>{t('Filter by')}</button>
                            </div>
                            <p className={clsx(styles.count)}><span>{t('All Images')}: </span>{filteredCount}</p>
                        </div>
                        <div className={clsx(styles.categoryListWrapper)}>
                            {checkedCategories.map((category: ImageCategory) => (
                                <div className={clsx(styles.box)} key={category}>
                                    <p className={clsx(styles.item)}>{t(category.toString())}</p>
                                    <span onClick={() => unselectedThisCategory(category)}>{GetIconByName('close', '20px', 'small', 'gray')}</span>

                                </div>
                            ))}
                            
                        </div>
                    </div>
                    {
                        stateIsOpenFilterDialog &&
                        <div className={clsx(styles.filterWrapper)}>
                            <p className={clsx(styles.heading)}>{t('Filter')}</p>
                            {
                                stateIsSelectAll === true ?
                                    <div className={clsx(styles.row)} onClick={clearFilter}>
                                        <span>{GetIconByName('close')}</span>
                                        <label style={{ fontWeight: 'bold', color: '#0d6efd' }}>{t('Clear all')}</label>
                                    </div>
                                    :
                                    <div className={clsx(styles.row)} onClick={selectAll}>
                                        <span>{GetIconByName('list')}</span>
                                        <label style={{ fontWeight: 'bold', color: '#0d6efd' }}>{t('Select all')}</label>
                                    </div>
                            }
                            {Object.values(ImageCategory).map(category => (
                                <div key={category} className={clsx(styles.row)}>
                                    <input
                                        type="checkbox"
                                        checked={checkedCategories.includes(category)}
                                        onChange={() => handleCheckboxChange(category)}
                                    />
                                    <label>{t(category)}</label>
                                </div>
                            ))}
                        </div>
                    }

                </div>
                <div className={clsx(styles.right)}>
                    {
                        stateArrDataImages_afterFilterGalleryType_andImageCategory.length > 0 &&
                        stateArrDataImages_afterFilterGalleryType_andImageCategory.map((obj) => {
                            // Check if obj.href is empty to decide which view to render
                            if (obj.href === '') {
                                return (
                                    <div className={clsx(styles.frame)} key={obj.id} onClick={() => selectImageToSeeBigger(obj.url)}>
                                        <div className={clsx(styles.innerFrame)}>
                                            <img src={obj.url} alt="not available" />
                                        </div>
                                    </div>
                                );
                            } else {
                                return (
                                    <div key={obj.id} className={clsx(styles.view360)}>
                                        <div className={clsx(styles.innerFrame)}>
                                            <a href={obj.href} target="_blank" rel="noopener noreferrer">
                                                <img src={obj.url} alt="not available" className={clsx(styles.preview)}/>
                                                <img src={view360} alt="not available" className={clsx(styles.iconView360)}/>
                                            </a>
                                        </div>

                                    </div>
                                );
                            }
                        })
                    }
                </div>
            </div>
            {
                stateStringUrlOfSelectedImage !== '' ?
                    <div className={clsx(styles.dialogImage)}>
                        <div className={clsx(styles.frame)}>
                            <div className={clsx(styles.innerFrame)}>
                                <img src={stateStringUrlOfSelectedImage} alt="not available" />
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            <div className={clsx(styles.backShadow, { [styles.appear]: stateBackShadow })} onClick={handleCloseFilter}></div>
            <div className={clsx(styles.backShadow, { [styles.appear]: stateBackShadow2 })} onClick={handleCloseDialogImage}></div>
        </div>
    )
})
export default Gallery;