// Define the interface for each menu item
export interface MenuBarItem {
   id: number;
   path: string;       // Path of the route
   title: string;      // Title of the menu item
   status: boolean;    // Status to show if the item is active or not
   iconLeft: string;   // Icon on the left (could be an icon name or path)
   iconRight: boolean; // Flag to indicate if there's an icon on the right
   node: number;       // Level of the menu item
   children: MenuBarItem[]; // Array of child menu items
}

// Define the interface for the routes
export interface MenuBarRoutes {
   PRODUCTGROUP: string;
   GALLERY: string;
   LIENHE: string;
   CATALOGS: string;
}
export const GuestMenuBarRoutes : MenuBarRoutes = {
   PRODUCTGROUP: "/productgroup",
   GALLERY: "/gallery",
   LIENHE: "/lienhe",
   CATALOGS: "/catalogs",
}
export const GuestMenuBarItems : MenuBarItem[]= [
   {
      id: 1,
      path: GuestMenuBarRoutes.PRODUCTGROUP,
      title: "Products",
      status: true,
      iconLeft: "",
      iconRight: false,
      node: 1, // 1 là cấp ngoài cùng, 2 là lớp kế, 3.....
      children: []
   },
   {
      id: 2,
      path: GuestMenuBarRoutes.GALLERY,
      title: "Gallery",
      status: true,
      iconLeft: "",
      iconRight: false,
      node: 1, // 1 là cấp ngoài cùng, 2 là lớp kế, 3.....
      children: []
   },
   {
      id: 3,
      path: GuestMenuBarRoutes.CATALOGS,
      title: "Catalogs",
      status: true,
      iconLeft: "",
      iconRight: false,
      node: 1, // 1 là cấp ngoài cùng, 2 là lớp kế, 3.....
      children: []
   },
   {
      id: 4,
      path: GuestMenuBarRoutes.LIENHE,
      title: "Contact",
      status: true,
      iconLeft: "",
      iconRight: false,
      node: 1, // 1 là cấp ngoài cùng, 2 là lớp kế, 3.....
      children: []
   },


]
