import { makeAutoObservable } from "mobx";
import { makePersistable, isPersisting, stopPersisting } from "mobx-persist-store";


export class ProductStore {
    productGroupId: number = 0;
    productGroupName: string = '';
    productId: number = 0;
    productName: string = '';
    productVariant: number = 0;
    productVariantName: string = '';

    constructor() {
        makeAutoObservable(this);

        // Only stop persisting if already persisting, to avoid memory leaks
        if (isPersisting(this)) {
            stopPersisting(this);
        }

        // Persist only if not already persisted
        makePersistable(this, {
            name: 'ProductStore',
            properties: ['productGroupId', 'productId', 'productVariant'],
            storage: window.localStorage
        });
    }

    updateProductGroupId(id: number) {
        this.productGroupId = id;
    }

    updateProductId(id: number) {
        this.productId = id;
    }

    updateProductVariant(variant: number) {
        this.productVariant = variant;
    }
    updateProductGroupName(name: string) {
        this.productGroupName = name;
    }

    updateProductName(name: string) {
        this.productName = name;
    }

    updateProductVariantName(name: string) {
        this.productVariantName = name;
    }
}
