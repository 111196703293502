import axios from 'axios';
import { appStore } from '../stores/AppStore';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE || 'http://localhost:3001/api',
    headers: {
        'Content-type': 'application/json',
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
    responseType: 'json',
});

// Add token to the request headers
axiosInstance.interceptors.request.use(function (config) {
    const store = appStore.authStore;

    if (store?.token && !config.headers['Authorization']) {
        config.headers['Authorization'] = `Bearer ${store.token}`;
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

// Handle responses and logout on 401
axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    const store = appStore.authStore;

    // 401 Unauthorized: The client must authenticate itself to get the requested response.
    if (error.response && error.response.status === 401) {
        alert("Bạn cần phải sign in lại. Xin cảm ơn")
        store.storeLogout();
        window.location.href = '/signin'; // Redirect to signin page
    }

    return Promise.reject(error);
});

export const baseUrl: string | undefined = process.env.REACT_APP_API_BASE;

export default axiosInstance;
