import { useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from "react-router-dom";
import styles from './GuestMenuBar.module.scss'
import { observer } from "mobx-react-lite";
import { useAppStore } from '../../../stores/AppStore';
import { GuestMenuBarItems } from '../../../routes/route-option-GuestMenuBar';
import { ReactComponent as IconVN } from '../../../assets/images/flags/vn.svg';
import { ReactComponent as IconUSA } from '../../../assets/images/flags/usa.svg';
import PasswordIcon from '@mui/icons-material/Password';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Logout from '@mui/icons-material/Logout';
import MenuCircle from '../../common/MenuCircle';
import { useTranslation } from 'react-i18next';
import { GuestSidebar } from '../GuestSideBar/GuestSidebar';
import { MenuItemDropDown } from '../../common/MenuItemDropDown';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { beadboard, CompositeInteriorDoor, DecorativeFlutedFlatWallPanel, logo, Moulding, moulding_gallery, nucedar, PVCFoamSheetLaminatedFoamSheet, TrimSheet, WallPartition } from '../../../assets/images';


export const GuestMenuBar = observer(() => {
    const { productStore, authStore, galleryStore } = useAppStore();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [stateString_menuItem, setStateString_menuItem] = useState<string>('');

    const selectMenuItem = (title: string) => {

        setStateString_menuItem(title);
        switch (title) {
            case 'Products':
                navigate("/productgroup", { replace: true });

                break;
            case 'Gallery':
                navigate("/gallery", { replace: true });

                break;
            case 'Sign In':
                navigate("/signin", { replace: true });
                break;
            case 'Sign Out':
                authStore.storeLogout();
                navigate("/productgroup", { replace: true });
                break;
            case 'Change Password':
                navigate("/changepassword", { replace: true });
                break;
            case 'Update Phone':
                navigate("/updatephone", { replace: true });
                break;
            case 'Contact':
                navigate("/contact", { replace: true });
                break;
            case 'Catalogs':
                navigate("/catalog", { replace: true });
                break;
            default:
                break;
        }
    }
    const selectProductGroup = (id: number) => {
        productStore.updateProductGroupId(id);
        navigate(`/product/${id}`, { replace: true });
    };
    const selectGalleryType = (galleryType: string) => {
    
        galleryStore.updateGallerySelected(galleryType);
        navigate(`/gallery/${galleryType}`, { replace: true });
        
    }
    return (
        <div className={clsx(styles.GuestMenuBar)}>
            <div className={clsx(styles.topMenuBar)}>
                <div className={clsx(styles.logoWrapper)}>
                    <img src={logo} className="avatar" alt="avatar" />
                </div>
                <div className={clsx(styles.menuItemWrapper)}>
                    {
                        GuestMenuBarItems.map((obj, index) => {
                            return (
                                <div
                                    key={obj.id}
                                    className={clsx(
                                        styles.menuItem,
                                        { [styles.selectedmenuItem]: obj.title === stateString_menuItem },
                                        styles[obj.title]
                                    )}
                                >
                                    <span onClick={() => selectMenuItem(obj.title)}>
                                        {t(obj.title).toUpperCase()}
                                    </span>
                                    <div className={clsx(styles.subMenu, styles.subMenu_product)}>
                                        <div className={clsx(styles.box)} onClick={()=>selectProductGroup(1)}>
                                            <img src={PVCFoamSheetLaminatedFoamSheet} alt="product" />
                                            <span>PVC Foam Sheet & Laminated Foam Sheet</span>
                                        </div>
                                        <div className={clsx(styles.box)} onClick={() => selectProductGroup(2)}>
                                            <img src={DecorativeFlutedFlatWallPanel} alt="product" />
                                            <span className={clsx(styles.name)}>Decorative (Fluted & Flat) Wall Panel</span>
                                        </div>
                                        <div className={clsx(styles.box)} onClick={() => selectProductGroup(3)}>
                                            <img src={CompositeInteriorDoor} className={clsx(styles.door)} alt="product" />
                                            <span className={clsx(styles.name)}>Composite Interior Door</span>
                                        </div>
                                        <div className={clsx(styles.box)} onClick={() => selectProductGroup(4)}>
                                            <img src={WallPartition} alt="product" />
                                            <span className={clsx(styles.name)}>Wall Partition</span>
                                        </div>
                                        <div className={clsx(styles.box)} onClick={() => selectProductGroup(5)}>
                                            <img src={Moulding} alt="product" />
                                            <span className={clsx(styles.name)}>Moulding</span>
                                        </div>
                                        <div className={clsx(styles.box)} onClick={() => selectProductGroup(6)}>
                                            <img src={TrimSheet} alt="product" />
                                            <span className={clsx(styles.name)}>Trim & Sheet</span>
                                        </div>
                                    </div>
                                    <div className={clsx(styles.subMenu, styles.subMenu_gallery)}>
                                        <div className={clsx(styles.box)} onClick={()=>selectGalleryType('Moulding')}>
                                            <img src={moulding_gallery} alt="gallery" />
                                            <span className={clsx(styles.name)}>MOULDING INSPIRATION</span>
                                        </div>
                                        <div className={clsx(styles.box)} onClick={()=>selectGalleryType('Nucedar')}>
                                            <img src={nucedar} alt="gallery" />
                                            <span className={clsx(styles.name)}>NUCEDAR INSPIRATION</span>
                                        </div>
                                        <div className={clsx(styles.box)} onClick={()=>selectGalleryType('Beadboard')}>
                                            <img src={beadboard} alt="gallery" />
                                            <span className={clsx(styles.name)}>BEADBOARD INSPIRATION</span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }

                </div>
                <div className={clsx(styles.right)}>
                    <div className={clsx(styles.langWrapper)}>
                        <MenuItemDropDown
                            title="Language"
                            dropdownItems={[
                                { label: 'Vietnam', icon: IconVN },
                                { label: 'English', icon: IconUSA },]}
                            iconFront={true}
                        />

                    </div>
                    <div className={clsx(styles.settings)}>

                        <MenuCircle
                            menuItems={
                                [
                                    {
                                        label: authStore.isAuthenticated === false ? 'Sign In' : 'Sign Out',
                                        icon: <Logout fontSize="small" />,
                                        iconFront: true
                                    },
                                    ...(authStore.isAuthenticated ? [
                                        { label: 'Change Password', icon: <PasswordIcon fontSize="small" />, iconFront: true }
                                    ] : []),
                                    ...(authStore.isAuthenticated ? [
                                        { label: 'Update Phone', icon: <LocalPhoneIcon fontSize="small" />, iconFront: true }
                                    ] : []),
                                    { label: 'Orders', icon: <ListAltIcon fontSize="small" />, iconFront: true },
                                ]
                            }
                            onMenuItemClick={selectMenuItem}
                        />
                    </div>
                </div>
            </div>
            {/* <div className={clsx(styles.backShadow, { [styles.appear]: stateBackShadow })} 
            
            >
            </div> */}

            {/* <div className={clsx(styles.rightControl)}>
                <PlaygroundSpeedDial direction="left" />
            </div> */}

            <div className={clsx(styles.sideBar)}>
                <GuestSidebar />
            </div>
        </div>
    )
})