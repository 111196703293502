import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { GuestMenuBar } from '../components/layout/GuestMenuBar/GuestMenuBar';
import { Footer } from '../components/layout/footer/Footer';
import BottomMenuBar from '../components/layout/BottomMenuBar/BottomMenuBar';

interface PrivateRouteProps {
    isAuthenticated: boolean;
    allowedRoles: string[];
    userRole: string;
    redirectPath?: string;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
    isAuthenticated,
    allowedRoles,
    userRole,
    redirectPath = '/signin',
}) => {
    // Check if the user is authenticated and has one of the allowed roles
    if (isAuthenticated && allowedRoles.includes(userRole)) {
        return (
            <>
                <GuestMenuBar />
                <Outlet /> {/* This will render the child route component */}
                <Footer/>
                <BottomMenuBar/>

            </>
        );
    }

    // If not authenticated or role not allowed, redirect to the login page or an error page
    return <Navigate to={redirectPath} />;
};

export default PrivateRoute;
