import { makeAutoObservable } from "mobx";
import { makePersistable, isPersisting, stopPersisting } from "mobx-persist-store";


export class BottomMenuBarStore {
    selectedBox: string = "Products";

    constructor() {
        makeAutoObservable(this);

        // Only stop persisting if already persisting, to avoid memory leaks
        if (isPersisting(this)) {
            stopPersisting(this);
        }

        // Persist only if not already persisted
        makePersistable(this, {
            name: 'BottomMenuBarStore',
            properties: ['selectedBox'],
            storage: window.localStorage
        });
    }

    updateSelectedBox(name: string) {
        this.selectedBox = name;
    }
}
