import clsx from 'clsx';
import styles from './Footer.module.scss'
import { observer } from "mobx-react-lite";
import { useTranslation } from 'react-i18next';
import { GetIconByName } from '../../common/GetIconByName';
import { seal } from '../../../assets/images';
import Divider from '../../common/Divider';
import zIndex from '@mui/material/styles/zIndex';


export const Footer = observer(() => {
    const { t } = useTranslation();

    return (
        <div className={clsx(styles.Footer)} style={{zIndex: 100}}>
            <Divider width={70}/>
            <div className={clsx(styles.container)}>
                <div className={clsx(styles.left)}>
                <p className={clsx(styles.title)}>{t('Contact').toUpperCase()}</p>
                    <div className={clsx(styles.row)}>
                        <span className={clsx(styles.key)}>{t('Company name')}:</span>
                        <span className={clsx(styles.value)}>Pima Co.,Ltd</span>
                    </div>
                    <div className={clsx(styles.row)}>
                        <span className={clsx(styles.key)}>{t('Person in charge')}:</span>
                        <span className={clsx(styles.value)}>Mr. Tuan Truong</span>
                    </div>
                    <div className={clsx(styles.row)}>
                        <span className={clsx(styles.key)}>Email:</span>
                        <a className={clsx(styles.link)} 
                            href="mailto:tuan.truong@pima.com.vn?subject=Subject&body=Dear%20Mr.Tuan,%0Ayour%20content%0A"
                            style={{
                                color: 'white', 
                                textDecoration: 'none', // Remove underline
                                fontWeight: 'bold',
                                transition: 'color 0.3s ease'
                            }}
                            onMouseOver={(e) => (e.currentTarget.style.color = '#f2f700')} // Hover color
                            onMouseOut={(e) => (e.currentTarget.style.color = 'white')} // Original color
                        >
                            tuan.truong@pima.com.vn
                        </a>
                    </div>
                    <div className={clsx(styles.row)}>
                        <span className={clsx(styles.key)}>Phone/WhatsApp:</span>
                        <span className={clsx(styles.value)}>+84 919 287 776</span>
                    </div>
                </div>
                <div className={clsx(styles.right)}>
                    <div className={clsx(styles.sealWrapper)}>
                    <img src={seal} className="avatar" alt="avatar" />
            
                    </div>
                    <div className={clsx(styles.connect)}>
                        <p className={clsx(styles.title)}>{t('Connect with us')}:</p>
                        <div className={clsx(styles.platform)}>
                            {GetIconByName("YouTubeIcon")}
                            {GetIconByName("FacebookIcon")}
                        </div>
                    </div>
                    <p className={clsx(styles.item)}>© Copyright.</p>
                    <p className={clsx(styles.item)}>{GetIconByName("CodeIcon")}&nbsp; {t('Site by our in-house development team')}.</p>
                </div>
            </div>
        </div>
    )
})