// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Define your translations
const resources = {
    English: {
        translation: {
        Contact: "Contact",
        Language: "Language",
        Products: "Products",
        News: "News",
        Cart: "Cart",
        Settings: "Settings",
        "Sign In": "Sign In",
        "Sign Out": "Sign Out",
        "Change Password": "Change Password",
        "Change Phone": "Change Phone",
        "Orders": "Orders",
        "Old password": "Old password",
        "New password": "New password",
        "Password confirmed": "Password confirmed",
        "Password must have at least 8 characters, maximum 30 characters, including lowercase, uppercase, special character, and number.":"Password must have at least 8 characters, maximum 30 characters, including lowercase, uppercase, special character, and number.",
        "New password is required":"New password is required",
        "Old password is required":"Old password is required",
        "Passwords must match":"Passwords must match",
        "Please retype your password to confirm":"Please retype your password to confirm",
        "Submit":"Submit",
        "Reset":"Reset",
        "Change password successfully":"Change password successfully",
        "Change password failed":"Change password failed",
        "Phone":"Phone",
        "New Phone":"New Phone",
        "Password":"Password",
        "User does not exist!":"User does not exist!",
        "Old password is incorrect!":"Old password is incorrect!",
        "Passwords do not match!":"Passwords do not match!",
        "Password updated successfully!":"Password updated successfully!",
        "Your password is incorrect. Sign in failed 1 times, if you sign in failed 5 times, your account will be locked": "Your password is incorrect. Sign in failed 1 times, if you sign in failed 5 times, your account will be locked",
        "Your password is incorrect. Sign in failed 2 times, if you sign in failed 5 times, your account will be locked": "Your password is incorrect. Sign in failed 2 times, if you sign in failed 5 times, your account will be locked",
        "Your password is incorrect. Sign in failed 3 times, if you sign in failed 5 times, your account will be locked": "Your password is incorrect. Sign in failed 3 times, if you sign in failed 5 times, your account will be locked",
        "Your password is incorrect. Sign in failed 4 times, if you sign in failed 5 times, your account will be locked": "Your password is incorrect. Sign in failed 1 times, if you sign in failed 5 times, your account will be locked",
        "Your account has been locked, please contact the company to recover it.":"Your account has been locked, please contact the company to recover it."
        ,"Phone Sign in incorrect":"Phone Sign in incorrect"
        ,"Login wrong 5 times, your account has been locked, please contact the company to resolve.":"Login wrong 5 times, your account has been locked, please contact the company to resolve."
        ,"Current Phone":"Current Phone"
        ,"Update Phone":"Update Phone"
        ,"Phone number updated successfully!":"Phone number updated successfully!"
        ,"Gallery":"Gallery"
        ,"Catalogs":"Catalogs"
        ,"Company name":"Company name"
        ,"Person in charge":"Person in charge"
        ,"Head office":"Head office"
        ,"Main factory of the company":"Main factory of the company"
        ,"Connect with us":"Connect with us"
        ,"Site by our in-house development team":"Site by our in-house development team"
        ,"Foam Board, Cabinet Profile & accessories, Ceiling panel":"Foam Board, Cabinet Profile & accessories, Ceiling panel"
        ,"Ceiling Panel 2D":"Ceiling Panel 2D"
        ,"Ceiling Panel 3D":"Ceiling Panel 3D"
        ,"Wall Panel, Fluted Panel, Cabinet, Composite Door":"Wall Panel, Fluted Panel, Cabinet, Composite Door"
        ,"Marble":"Marble"
        ,"Composite Door":"Composite Door"
        ,"Wall panel, Wall Fluted Panel":"Wall panel, Wall Fluted Panel"
        ,"Pima Introduction":"Pima Introduction"
        ,"Pima specializes in producing durable, lightweight PVC Foam Sheets and Laminated Foam Sheets, perfect for signage, construction, and decorative uses. Known for quality and versatility, Pima delivers materials that combine strength and style.":"Pima specializes in producing durable, lightweight PVC Foam Sheets and Laminated Foam Sheets, perfect for signage, construction, and decorative uses. Known for quality and versatility, Pima delivers materials that combine strength and style."
        ,"PVC Foam Sheet & Laminated Foam Sheet":"PVC Foam Sheet & Laminated Foam Sheet"
        ,"View Details":"View Details"
        ,"Decorative (Fluted & Flat) Wall Panel":"Decorative (Fluted & Flat) Wall Panel"
        ,"Pima offers a range of Decorative Wall Panels, available in both Fluted and Flat designs. These panels combine aesthetic appeal with functionality, enhancing interior and exterior spaces with stylish and versatile solutions. Ideal for creating distinctive visual effects, Pima’s wall panels are crafted for durability and easy installation.":"Pima offers a range of Decorative Wall Panels, available in both Fluted and Flat designs. These panels combine aesthetic appeal with functionality, enhancing interior and exterior spaces with stylish and versatile solutions. Ideal for creating distinctive visual effects, Pima’s wall panels are crafted for durability and easy installation."
        ,"Door":"Door"
        ,"Pima produces high-quality doors designed for durability and style. Our doors combine aesthetic appeal with robust construction, making them ideal for both residential and commercial applications. Crafted with precision, Pima’s doors offer reliable performance and enhance any space with their elegant design.":"Pima produces high-quality doors designed for durability and style. Our doors combine aesthetic appeal with robust construction, making them ideal for both residential and commercial applications. Crafted with precision, Pima’s doors offer reliable performance and enhance any space with their elegant design."
        ,"Pima's Wall Partition products are designed for modern interiors, offering a lightweight yet durable solution for dividing spaces. Made from high-quality PVC foam, these partitions provide excellent insulation, soundproofing, and easy installation. They are ideal for both residential and commercial applications, combining aesthetics with functionality.":"Pima's Wall Partition products are designed for modern interiors, offering a lightweight yet durable solution for dividing spaces. Made from high-quality PVC foam, these partitions provide excellent insulation, soundproofing, and easy installation. They are ideal for both residential and commercial applications, combining aesthetics with functionality."
        ,"Wall Partition":"Wall Partition"
        ,"Moulding":"Moulding"
        ,"Pima's Moulding Decoration products offer a stylish and elegant solution for enhancing interior spaces. Made from high-quality PVC materials, they are lightweight, durable, and easy to install. These decorative mouldings add a refined finish to walls and ceilings, suitable for both residential and commercial environments, creating a sophisticated and classic look.":"Pima's Moulding Decoration products offer a stylish and elegant solution for enhancing interior spaces. Made from high-quality PVC materials, they are lightweight, durable, and easy to install. These decorative mouldings add a refined finish to walls and ceilings, suitable for both residential and commercial environments, creating a sophisticated and classic look."
        ,"Trim & Sheet":"Trim & Sheet"
        ,"Pima's Trim & Sheet Decoration products are designed to add a polished and professional finish to various surfaces. These trims and sheets, made from high-quality PVC materials, are lightweight, durable, and easy to install. Ideal for enhancing the appearance of walls, floors, and ceilings, they offer both decorative and functional benefits, making them suitable for residential and commercial projects.":"Pima's Trim & Sheet Decoration products are designed to add a polished and professional finish to various surfaces. These trims and sheets, made from high-quality PVC materials, are lightweight, durable, and easy to install. Ideal for enhancing the appearance of walls, floors, and ceilings, they offer both decorative and functional benefits, making them suitable for residential and commercial projects."
        ,"A sleek and modern wall panel featuring a smooth, flat surface. Ideal for creating clean, contemporary interiors, this panel is perfect for both residential and commercial applications, providing a seamless and stylish finish.":"A sleek and modern wall panel featuring a smooth, flat surface. Ideal for creating clean, contemporary interiors, this panel is perfect for both residential and commercial applications, providing a seamless and stylish finish."
        ,"This wall panel showcases vertical fluting that adds texture and visual interest to any space. The fluted design is perfect for creating a sense of elegance and depth on walls, making it a popular choice for upscale interiors.":"This wall panel showcases vertical fluting that adds texture and visual interest to any space. The fluted design is perfect for creating a sense of elegance and depth on walls, making it a popular choice for upscale interiors."
        ,"A durable and stylish composite door featuring a fluted design. Combining the strength of composite materials with a unique aesthetic, this door offers both functionality and visual appeal, making it suitable for high-traffic areas.":"A durable and stylish composite door featuring a fluted design. Combining the strength of composite materials with a unique aesthetic, this door offers both functionality and visual appeal, making it suitable for high-traffic areas."
        ,"An eye-catching door with decorative metal braces that add a touch of sophistication and industrial charm. Ideal for creating a statement piece in any space, this door combines strength with artistic design.":"An eye-catching door with decorative metal braces that add a touch of sophistication and industrial charm. Ideal for creating a statement piece in any space, this door combines strength with artistic design."
        ,"A modern composite door featuring glass inserts (lites) for a sleek and stylish appearance. The glass elements allow natural light to flow through while maintaining the durability and insulation properties of composite materials.":"A modern composite door featuring glass inserts (lites) for a sleek and stylish appearance. The glass elements allow natural light to flow through while maintaining the durability and insulation properties of composite materials."
        ,"A composite door with a panel design, offering a classic and versatile look. The panel configuration adds texture and depth, making it a great choice for traditional and contemporary settings alike.":"A composite door with a panel design, offering a classic and versatile look. The panel configuration adds texture and depth, making it a great choice for traditional and contemporary settings alike."
        ,"A traditional-style composite door with a vacuum-formed design, providing both elegance and durability. This door features intricate detailing reminiscent of colonial architecture, perfect for adding a touch of classic charm to any home.":"A traditional-style composite door with a vacuum-formed design, providing both elegance and durability. This door features intricate detailing reminiscent of colonial architecture, perfect for adding a touch of classic charm to any home."
        ,"A composite door with a graceful arched top, offering a timeless and elegant appearance. The arched design adds a distinctive architectural element, making it a perfect choice for enhancing the curb appeal of any property.":"A composite door with a graceful arched top, offering a timeless and elegant appearance. The arched design adds a distinctive architectural element, making it a perfect choice for enhancing the curb appeal of any property."
        ,"A versatile panel designed for creating partitions and dividing spaces. Ideal for offices, commercial spaces, and residential areas, this panel offers both functionality and aesthetic flexibility for customizing interiors.":"A versatile panel designed for creating partitions and dividing spaces. Ideal for offices, commercial spaces, and residential areas, this panel offers both functionality and aesthetic flexibility for customizing interiors."
        ,"A durable and stylish timber tube used for various decorative and structural applications. Its natural wood finish adds warmth and character to any project, making it suitable for both traditional and modern designs.":"A durable and stylish timber tube used for various decorative and structural applications. Its natural wood finish adds warmth and character to any project, making it suitable for both traditional and modern designs."
        ,"Elegant moulding designed to crown the top of walls and add a finishing touch to interiors. Crown moulding enhances the visual appeal of a room by bridging the gap between the walls and ceiling, offering a sophisticated look.":"Elegant moulding designed to crown the top of walls and add a finishing touch to interiors. Crown moulding enhances the visual appeal of a room by bridging the gap between the walls and ceiling, offering a sophisticated look."
        ,"A trim piece used to frame doors and windows, providing a polished and completed appearance. Casing adds architectural detail and helps to create a cohesive look throughout the interior.":"A trim piece used to frame doors and windows, providing a polished and completed appearance. Casing adds architectural detail and helps to create a cohesive look throughout the interior."
        ,"A decorative trim used to cap the top of baseboards and add a finishing touch to the lower part of walls. Base caps provide a clean and stylish transition between walls and floors, enhancing the overall design of a room.":"A decorative trim used to cap the top of baseboards and add a finishing touch to the lower part of walls. Base caps provide a clean and stylish transition between walls and floors, enhancing the overall design of a room."
        ,"Versatile trim pieces used to add decorative elements and finishing touches to various parts of a room. Decorative trims can be used around doors, windows, and other architectural features to enhance the visual appeal of a space.":"Versatile trim pieces used to add decorative elements and finishing touches to various parts of a room. Decorative trims can be used around doors, windows, and other architectural features to enhance the visual appeal of a space."
        ,"Trim designed to cover the corners and ends of panels or surfaces, providing a clean and finished look. Corner trims are essential for creating seamless transitions and protecting edges from damage, while also adding a decorative element.":"Trim designed to cover the corners and ends of panels or surfaces, providing a clean and finished look. Corner trims are essential for creating seamless transitions and protecting edges from damage, while also adding a decorative element."
        ,"- Pima (PVC) Foam Board is one of the most widely plastic products using in the world today. It offers a diverse and varied range of properties, and contributes significantly to the modern construction and decoration industry. Its popularity comes from various highlighted benefits such as: durability, water resistance, non-flammability, anti-moisture environmental friendly are effective insulators (ASTM D257-99).- Lead-Free: This characteristic strongly demonstrate the suitability of Pima PVC Foam to be ideal material for indoor furniture and certify its safety for lond-term contact with human body.- Non Formaldehyde: Pima PVC Foam is manufactured from pure PVC resin which extracted from kerosene. This component ensure no formaldehyde gas emit from the board, during construction process and normal usage.":"- Pima (PVC) Foam Board is one of the most widely plastic products using in the world today. It offers a diverse and varied range of properties, and contributes significantly to the modern construction and decoration industry. Its popularity comes from various highlighted benefits such as: durability, water resistance, non-flammability, anti-moisture environmental friendly are effective insulators (ASTM D257-99).- Lead-Free: This characteristic strongly demonstrate the suitability of Pima PVC Foam to be ideal material for indoor furniture and certify its safety for lond-term contact with human body.- Non Formaldehyde: Pima PVC Foam is manufactured from pure PVC resin which extracted from kerosene. This component ensure no formaldehyde gas emit from the board, during construction process and normal usage."
        // first 20 words description of product
        ,"- Pima (PVC) Foam Board is one of the most widely plastic products using in the world today. It offers ...":"- Pima (PVC) Foam Board is one of the most widely plastic products using in the world today. It offers ..."
        ,"A sleek and modern wall panel featuring a smooth, flat surface. Ideal for creating clean, contemporary interiors, this panel is ...":"A sleek and modern wall panel featuring a smooth, flat surface. Ideal for creating clean, contemporary interiors, this panel is ..."
        ,"This wall panel showcases vertical fluting that adds texture and visual interest to any space. The fluted design is perfect ...":"This wall panel showcases vertical fluting that adds texture and visual interest to any space. The fluted design is perfect ..."
        ,"A durable and stylish composite door featuring a fluted design. Combining the strength of composite materials with a unique aesthetic, ...":"A durable and stylish composite door featuring a fluted design. Combining the strength of composite materials with a unique aesthetic, ..."
        ,"An eye-catching door with decorative metal braces that add a touch of sophistication and industrial charm. Ideal for creating a ...":"An eye-catching door with decorative metal braces that add a touch of sophistication and industrial charm. Ideal for creating a ..."
        ,"A modern composite door featuring glass inserts (lites) for a sleek and stylish appearance. The glass elements allow natural light ...":"A modern composite door featuring glass inserts (lites) for a sleek and stylish appearance. The glass elements allow natural light ..."
        ,"A composite door with a panel design, offering a classic and versatile look. The panel configuration adds texture and depth, ...":"A composite door with a panel design, offering a classic and versatile look. The panel configuration adds texture and depth, ..."
        ,"A traditional-style composite door with a vacuum-formed design, providing both elegance and durability. This door features intricate detailing reminiscent of ...":"A traditional-style composite door with a vacuum-formed design, providing both elegance and durability. This door features intricate detailing reminiscent of ..."
        ,"A composite door with a graceful arched top, offering a timeless and elegant appearance. The arched design adds a distinctive ...":"A composite door with a graceful arched top, offering a timeless and elegant appearance. The arched design adds a distinctive ..."
        ,"A versatile panel designed for creating partitions and dividing spaces. Ideal for offices, commercial spaces, and residential areas, this panel ...":"A versatile panel designed for creating partitions and dividing spaces. Ideal for offices, commercial spaces, and residential areas, this panel ..."
        ,"A durable and stylish timber tube used for various decorative and structural applications. Its natural wood finish adds warmth and ...":"A durable and stylish timber tube used for various decorative and structural applications. Its natural wood finish adds warmth and ..."
        ,"Elegant moulding designed to crown the top of walls and add a finishing touch to interiors. Crown moulding enhances the ...":"Elegant moulding designed to crown the top of walls and add a finishing touch to interiors. Crown moulding enhances the ..."
        ,"A trim piece used to frame doors and windows, providing a polished and completed appearance. Casing adds architectural detail and ...":"A trim piece used to frame doors and windows, providing a polished and completed appearance. Casing adds architectural detail and ..."
        ,"A decorative trim used to cap the top of baseboards and add a finishing touch to the lower part of ...":"A decorative trim used to cap the top of baseboards and add a finishing touch to the lower part of ..."
        ,"Versatile trim pieces used to add decorative elements and finishing touches to various parts of a room. Decorative trims can ...":"Versatile trim pieces used to add decorative elements and finishing touches to various parts of a room. Decorative trims can ..."
        ,"Trim designed to cover the corners and ends of panels or surfaces, providing a clean and finished look. Corner trims ...":"Trim designed to cover the corners and ends of panels or surfaces, providing a clean and finished look. Corner trims ..."
        // product name
        ,"PVC Foam Sheet":"PVC Foam Sheet"
        ,"Laminated Foam Sheet":"Laminated Foam Sheet"
        ,"Co-extrusion Sheet":"Co-extrusion Sheet"
        ,"Flat Wall Panel":"Flat Wall Panel"
        ,"Fluted Wall Panel":"Fluted Wall Panel"
        ,"Flut Composite Door":"Flut Composite Door"
        ,"Decorative metal Brace Door":"Decorative metal Brace Door"
        ,"Glass (Lite) Composite Door":"Glass (Lite) Composite Door"
        ,"Panels Composite Door":"Panels Composite Door"
        ,"Colonial (Vacuumed) Composite Door":"Colonial (Vacuumed) Composite Door"
        ,"Arched Composite Door":"Arched Composite Door"
        ,"Wall Partition Panel":"Wall Partition Panel"
        ,"Timber Tube":"Timber Tube"
        ,"Crown Moulding":"Crown Moulding"
        ,"Casing":"Casing"
        ,"Base Cap":"Base Cap"
        ,"Decorative Trim":"Decorative Trim"
        ,"Corner (End) Trim":"Corner (End) Trim"
        // product variant details
        ,"Name":"Name"
        ,"Weight":"Weight"
        ,"Dimensions":"Dimensions"
        ,"Color":"Color"
        ,"Size":"Size"
        ,"Previous Page":"Previous Page"
        ,"Next Page":"Next Page"
        ,"View PDF":"View PDF"
        ,"Download":"Download"
        //gallery
        ,"Filter":"Filter"
        ,"All Images":"All Images"
        ,"Clear all":"Clear all"
        ,"Filter by":"Filter by"
        ,"Living Room":"Living Room"
        ,"Bedroom":"Bedroom"
        ,"Kitchen":"Kitchen"
        ,"Bathroom":"Bathroom"
        ,"Hallways and Foyers":"Hallways and Foyers"
        ,"Dining Room":"Dining Room"
        ,"Office":"Office"
        ,"Media Room":"Media Room"
        ,"Laundry Room":"Laundry Room"
        ,"Closet":"Closet"
        ,"Library":"Library"
        ,"Terrace":"Terrace"
        ,"Art Studio":"Art Studio"
        ,"Billiard Room":"Billiard Room"
        ,"Lounge":"Lounge"
        ,"Pool":"Pool"
        ,"Wine Room":"Wine Room"
        ,"Select all":"Select all"
        ,"Group Product":"Group Product"
        ,"Door Composite Pima 2024":"Door Composite Pima 2024"
        ,"Catalog Pima All Products":"Catalog Pima All Products"
        // ,"":""
        
      },
    },
    Vietnam: {
      translation: {
        "Door Composite Pima 2024":"Cửa Composite Pima 2024",
        "Catalog Pima All Products":"Catalog toàn bộ sản phẩm Pima",
        // "":"",
        "Group Product":"Ngành hàng",
        "Select all":"Chọn tất cả",
        //gallery
        "Living Room":"Phòng khách",
        "Bedroom":"Phòng ngủ",
        "Kitchen":"Nhà bếp",
        "Bathroom":"Phòng tắm",
        "Hallways and Foyers":"Hành lang và sảnh",
        "Dining Room":"Phòng ăn",
        "Office":"Văn phòng",
        "Media Room":"Phòng media",
        "Laundry Room":"Phòng giặt",
        "Closet":"Tủ quần áo",
        "Library":"Thư viện",
        "Terrace":"Sân thượng",
        "Art Studio":"Phòng nghệ thuật",
        "Billiard Room":"Phòng bi-a",
        "Lounge":"Phòng chờ",
        "Pool":"Hồ bơi",
        "Wine Room":"Phòng rượu",
        "Filter by":"Bộ lọc",
        "Clear all":"Bỏ chọn tất cả",
        "All Images":"Tổng hình",
        "Filter":"Bộ lọc",
        "View PDF":"Xem PDF",
        "Download":"Tải xuống",
        "Previous Page":"Trang trước",
        "Next Page":"Trang sau",
        // product variant details
        "Name":"Tên sản phẩm",
        "Weight":"Cân nặng",
        "Dimensions":"Kích thước",
        "Color":"Màu sắc",
        "Size":"Độ lớn",
        // product name
        "PVC Foam Sheet":"Tấm xốp PVC",
        "Laminated Foam Sheet":"Tấm xốp phủ laminate",
        "Co-extrusion Sheet":"Tấm đồng đùn",
        "Flat Wall Panel":"Tấm tường phẳng",
        "Fluted Wall Panel":"Tấm tường có rãnh",
        "Flut Composite Door":"Cửa composite có rãnh",
        "Decorative metal Brace Door":"Cửa trang trí với thanh kim loại",
        "Glass (Lite) Composite Door":"Cửa composite kính (lite)",
        "Panels Composite Door":"Cửa composite dạng panel",
        "Colonial (Vacuumed) Composite Door":"Cửa composite kiểu Colonial (hút chân không)",
        "Arched Composite Door":"Cửa composite có vòm",
        "Wall Partition Panel":"Tấm vách ngăn tường",
        "Timber Tube":"Ống gỗ",
        "Crown Moulding":"Phào chỉ vương miện",
        "Casing":"Viền khung cửa",
        "Base Cap":"Viền chân tường",
        "Decorative Trim":"Viền trang trí",
        "Corner (End) Trim":"Viền góc (kết thúc)",
        // first 20 words description of product
        "- Pima (PVC) Foam Board is one of the most widely plastic products using in the world today. It offers ...":"Tấm xốp Pima (PVC) là một trong những sản phẩm nhựa được sử dụng rộng rãi nhất trên thế giới hiện nay. Nó mang lại...",
        "A sleek and modern wall panel featuring a smooth, flat surface. Ideal for creating clean, contemporary interiors, this panel is ...":"Tấm tường hiện đại và bóng mượt với bề mặt phẳng mịn. Lý tưởng để tạo ra không gian nội thất sạch sẽ và đương đại, tấm này...",
        "This wall panel showcases vertical fluting that adds texture and visual interest to any space. The fluted design is perfect ...":"Tấm tường này có rãnh dọc, mang lại kết cấu và sự thu hút thị giác cho bất kỳ không gian nào. Thiết kế có rãnh là lựa chọn hoàn hảo...",
        "A durable and stylish composite door featuring a fluted design. Combining the strength of composite materials with a unique aesthetic, ...":"Cửa composite bền bỉ và thời trang với thiết kế có rãnh. Kết hợp sức mạnh của vật liệu composite với vẻ đẹp độc đáo...",
        "An eye-catching door with decorative metal braces that add a touch of sophistication and industrial charm. Ideal for creating a ...":"Cửa thu hút với thanh kim loại trang trí, mang lại vẻ tinh tế và sự hấp dẫn theo phong cách công nghiệp. Lý tưởng để tạo ra...",
        "A modern composite door featuring glass inserts (lites) for a sleek and stylish appearance. The glass elements allow natural light ...":"Cửa composite hiện đại với các tấm kính lắp vào (kính sáng), mang lại vẻ ngoài bóng bẩy và thời trang. Các yếu tố kính cho phép ánh sáng tự nhiên...",
        "A composite door with a panel design, offering a classic and versatile look. The panel configuration adds texture and depth, ...":"Cửa composite với thiết kế theo dạng panel, mang lại vẻ cổ điển và linh hoạt. Cấu hình panel tạo thêm kết cấu và chiều sâu...",
        "A traditional-style composite door with a vacuum-formed design, providing both elegance and durability. This door features intricate detailing reminiscent of ...":"Cửa composite phong cách truyền thống với thiết kế hút chân không, mang lại sự thanh lịch và độ bền. Cửa này có các chi tiết tinh xảo gợi nhớ đến...",
        "A composite door with a graceful arched top, offering a timeless and elegant appearance. The arched design adds a distinctive ...":"Cửa composite với đỉnh vòm uyển chuyển, mang lại vẻ đẹp thanh lịch và vượt thời gian. Thiết kế hình vòm thêm nét đặc trưng...",
        "A versatile panel designed for creating partitions and dividing spaces. Ideal for offices, commercial spaces, and residential areas, this panel ...":"Tấm panel đa năng được thiết kế để tạo ra các vách ngăn và phân chia không gian. Lý tưởng cho văn phòng, không gian thương mại và khu vực dân cư...",
        "A durable and stylish timber tube used for various decorative and structural applications. Its natural wood finish adds warmth and ...":"Ống gỗ bền bỉ và thời trang, được sử dụng cho nhiều ứng dụng trang trí và kết cấu khác nhau. Bề mặt gỗ tự nhiên của nó mang lại sự ấm áp và...",
        "Elegant moulding designed to crown the top of walls and add a finishing touch to interiors. Crown moulding enhances the ...":"Phào chỉ thanh lịch được thiết kế để làm đẹp phần trên của tường và tạo thêm nét hoàn thiện cho nội thất. Phào chỉ vương miện làm tăng thêm sự sang trọng...",
        "A trim piece used to frame doors and windows, providing a polished and completed appearance. Casing adds architectural detail and ...":"Một miếng viền được sử dụng để khung cửa và cửa sổ, mang lại vẻ ngoài hoàn thiện và tinh tế. Viền khung cửa làm tăng chi tiết kiến trúc và...",
        "A decorative trim used to cap the top of baseboards and add a finishing touch to the lower part of ...":"Một miếng viền trang trí được sử dụng để phủ phần trên của tấm chân tường và hoàn thiện phần dưới của tường. Viền trang trí giúp...",
        "Versatile trim pieces used to add decorative elements and finishing touches to various parts of a room. Decorative trims can ...":"Các miếng viền đa năng được sử dụng để thêm các yếu tố trang trí và hoàn thiện cho nhiều phần của căn phòng. Viền trang trí có thể...",
        "Trim designed to cover the corners and ends of panels or surfaces, providing a clean and finished look. Corner trims ...":"Viền được thiết kế để phủ các góc và đầu của các tấm hoặc bề mặt, mang lại vẻ ngoài sạch sẽ và hoàn thiện. Viền góc...",

        "- Pima (PVC) Foam Board is one of the most widely plastic products using in the world today. It offers a diverse and varied range of properties, and contributes significantly to the modern construction and decoration industry. Its popularity comes from various highlighted benefits such as: durability, water resistance, non-flammability, anti-moisture environmental friendly are effective insulators (ASTM D257-99).- Lead-Free: This characteristic strongly demonstrate the suitability of Pima PVC Foam to be ideal material for indoor furniture and certify its safety for lond-term contact with human body.- Non Formaldehyde: Pima PVC Foam is manufactured from pure PVC resin which extracted from kerosene. This component ensure no formaldehyde gas emit from the board, during construction process and normal usage.":"Tấm Pima (PVC) Foam là một trong những sản phẩm nhựa được sử dụng rộng rãi nhất trên thế giới hiện nay. Nó cung cấp một loạt các tính chất đa dạng và phong phú, đóng góp đáng kể vào ngành xây dựng và trang trí hiện đại. Sự phổ biến của nó xuất phát từ nhiều lợi ích nổi bật như: độ bền, khả năng chống nước, không cháy, chống ẩm và thân thiện với môi trường, đồng thời là chất cách nhiệt hiệu quả (theo tiêu chuẩn ASTM D257-99).Không chứa chì: Đặc tính này thể hiện rõ sự phù hợp của tấm Pima PVC Foam để trở thành vật liệu lý tưởng cho nội thất trong nhà và chứng nhận sự an toàn khi tiếp xúc lâu dài với cơ thể con người.Không chứa Formaldehyde: Tấm Pima PVC Foam được sản xuất từ nhựa PVC nguyên chất, được chiết xuất từ dầu hỏa. Thành phần này đảm bảo không phát thải khí formaldehyde từ tấm ván, trong quá trình thi công và sử dụng bình thường.",
        "A sleek and modern wall panel featuring a smooth, flat surface. Ideal for creating clean, contemporary interiors, this panel is perfect for both residential and commercial applications, providing a seamless and stylish finish.":"Tấm tường hiện đại và thanh lịch với bề mặt phẳng mịn. Lý tưởng cho việc tạo ra các không gian nội thất sạch sẽ, đương đại, tấm này phù hợp cho cả ứng dụng dân dụng và thương mại, mang lại một vẻ ngoài liền mạch và tinh tế.",
        "This wall panel showcases vertical fluting that adds texture and visual interest to any space. The fluted design is perfect for creating a sense of elegance and depth on walls, making it a popular choice for upscale interiors.":"Tấm tường có rãnh dọc tạo thêm kết cấu và sự thú vị cho không gian. Thiết kế có rãnh giúp tạo cảm giác sang trọng và chiều sâu cho tường, là lựa chọn phổ biến cho các không gian cao cấp.",
        "A durable and stylish composite door featuring a fluted design. Combining the strength of composite materials with a unique aesthetic, this door offers both functionality and visual appeal, making it suitable for high-traffic areas.":"Cửa composite bền bỉ và thời trang với thiết kế có rãnh. Kết hợp sự chắc chắn của vật liệu composite với thẩm mỹ độc đáo, cửa này mang lại cả tính năng và vẻ đẹp, phù hợp với những khu vực có nhiều lưu lượng đi lại.",
        "An eye-catching door with decorative metal braces that add a touch of sophistication and industrial charm. Ideal for creating a statement piece in any space, this door combines strength with artistic design.":"Cánh cửa nổi bật với các thanh kim loại trang trí tạo nên vẻ tinh tế và sang trọng với một chút công nghiệp. Lý tưởng để tạo ra một điểm nhấn cho không gian, cửa này kết hợp sự chắc chắn và thiết kế nghệ thuật.",
        "A modern composite door featuring glass inserts (lites) for a sleek and stylish appearance. The glass elements allow natural light to flow through while maintaining the durability and insulation properties of composite materials.":"Cửa composite hiện đại với các chi tiết kính (lite) tạo vẻ ngoài thanh lịch và phong cách. Các yếu tố kính cho phép ánh sáng tự nhiên xuyên qua mà vẫn giữ được đặc tính bền vững và cách nhiệt của vật liệu composite.",
        "A composite door with a panel design, offering a classic and versatile look. The panel configuration adds texture and depth, making it a great choice for traditional and contemporary settings alike.":"Cửa composite với thiết kế các tấm panel, mang lại vẻ cổ điển và đa dụng. Cấu hình tấm panel tạo thêm kết cấu và chiều sâu, là lựa chọn tuyệt vời cho các không gian truyền thống và hiện đại.",
        "A traditional-style composite door with a vacuum-formed design, providing both elegance and durability. This door features intricate detailing reminiscent of colonial architecture, perfect for adding a touch of classic charm to any home.":"Cửa composite phong cách cổ điển với thiết kế hút chân không, mang lại sự tinh tế và bền bỉ. Cửa này có chi tiết phức tạp, gợi nhớ đến kiến trúc thuộc địa, hoàn hảo để thêm nét cổ điển cho ngôi nhà.",
        "A composite door with a graceful arched top, offering a timeless and elegant appearance. The arched design adds a distinctive architectural element, making it a perfect choice for enhancing the curb appeal of any property.":"Cửa composite với đỉnh vòm duyên dáng, mang lại vẻ ngoài cổ điển và thanh lịch. Thiết kế có vòm tạo thêm một yếu tố kiến trúc đặc biệt, là lựa chọn hoàn hảo để tăng thêm sự sang trọng cho ngoại thất.",
        "A versatile panel designed for creating partitions and dividing spaces. Ideal for offices, commercial spaces, and residential areas, this panel offers both functionality and aesthetic flexibility for customizing interiors.":"Tấm vách ngăn đa dụng được thiết kế để tạo ra các vách ngăn và phân chia không gian. Lý tưởng cho văn phòng, không gian thương mại và khu dân cư, tấm này mang lại cả tính năng và sự linh hoạt về thẩm mỹ để tùy chỉnh không gian nội thất.",
        "A durable and stylish timber tube used for various decorative and structural applications. Its natural wood finish adds warmth and character to any project, making it suitable for both traditional and modern designs.":"Ống gỗ bền bỉ và phong cách được sử dụng trong nhiều ứng dụng trang trí và cấu trúc. Hoàn thiện bằng gỗ tự nhiên tạo thêm sự ấm áp và tính cách cho bất kỳ dự án nào, phù hợp cho cả thiết kế truyền thống và hiện đại.",
        "Elegant moulding designed to crown the top of walls and add a finishing touch to interiors. Crown moulding enhances the visual appeal of a room by bridging the gap between the walls and ceiling, offering a sophisticated look.":"Đường chỉ trang trí được thiết kế để nối phần trên của tường, thêm một điểm nhấn hoàn thiện cho nội thất. Đường chỉ trần giúp tăng tính thẩm mỹ cho căn phòng bằng cách tạo sự chuyển tiếp mềm mại giữa tường và trần, mang lại vẻ sang trọng.",
        "A trim piece used to frame doors and windows, providing a polished and completed appearance. Casing adds architectural detail and helps to create a cohesive look throughout the interior.":"Chi tiết trang trí được dùng để bao quanh cửa và cửa sổ, mang lại vẻ ngoài hoàn chỉnh và tinh tế. Khung cửa thêm chi tiết kiến trúc và giúp tạo ra sự nhất quán trong thiết kế nội thất.",
        "A decorative trim used to cap the top of baseboards and add a finishing touch to the lower part of walls. Base caps provide a clean and stylish transition between walls and floors, enhancing the overall design of a room.":"Đường chỉ trang trí dùng để nắp lên đầu của chân tường, thêm điểm nhấn hoàn thiện cho phần dưới của tường. Viền chân tường cung cấp sự chuyển tiếp sạch sẽ và phong cách giữa tường và sàn nhà, nâng cao tổng thể thiết kế của căn phòng.",
        "Versatile trim pieces used to add decorative elements and finishing touches to various parts of a room. Decorative trims can be used around doors, windows, and other architectural features to enhance the visual appeal of a space.":"Các chi tiết đường chỉ đa dụng được sử dụng để thêm các yếu tố trang trí và hoàn thiện cho nhiều phần của căn phòng. Đường chỉ trang trí có thể được sử dụng quanh cửa, cửa sổ và các chi tiết kiến trúc khác để nâng cao tính thẩm mỹ cho không gian.",
        "Trim designed to cover the corners and ends of panels or surfaces, providing a clean and finished look. Corner trims are essential for creating seamless transitions and protecting edges from damage, while also adding a decorative element.":"Đường chỉ được thiết kế để bao phủ các góc và đầu của các tấm hoặc bề mặt, mang lại vẻ ngoài hoàn chỉnh và gọn gàng. Đường chỉ góc cần thiết để tạo sự chuyển tiếp liền mạch và bảo vệ các cạnh khỏi hư hỏng, đồng thời thêm yếu tố trang trí.",
        "Pima's Trim & Sheet Decoration products are designed to add a polished and professional finish to various surfaces. These trims and sheets, made from high-quality PVC materials, are lightweight, durable, and easy to install. Ideal for enhancing the appearance of walls, floors, and ceilings, they offer both decorative and functional benefits, making them suitable for residential and commercial projects.":"Các sản phẩm Thanh Trang Trí và Nẹp của Pima được thiết kế để tạo ra bề mặt hoàn thiện tinh tế và chuyên nghiệp cho nhiều loại bề mặt. Được làm từ vật liệu PVC chất lượng cao, các sản phẩm này nhẹ, bền và dễ lắp đặt. Chúng rất lý tưởng để làm đẹp cho tường, sàn và trần, mang lại cả tính thẩm mỹ lẫn công năng, phù hợp cho các dự án dân dụng và thương mại.",
        "Trim & Sheet":"Thanh Nẹp",
        "Pima's Moulding Decoration products offer a stylish and elegant solution for enhancing interior spaces. Made from high-quality PVC materials, they are lightweight, durable, and easy to install. These decorative mouldings add a refined finish to walls and ceilings, suitable for both residential and commercial environments, creating a sophisticated and classic look.":"Các sản phẩm đường Chỉ (Moulding) Trang Trí của Pima mang đến giải pháp tinh tế và sang trọng cho việc làm đẹp không gian nội thất. Được làm từ vật liệu PVC chất lượng cao, chúng nhẹ, bền và dễ lắp đặt. Các đường chỉ trang trí này tạo ra một kết thúc trang nhã cho tường và trần nhà, phù hợp cho cả môi trường dân dụng và thương mại, tạo nên một diện mạo thanh lịch và cổ điển.",
        "Moulding":"Đường chỉ trang chí",
        "Wall Partition":"Tấm Vách Ngăn",
        "Pima's Wall Partition products are designed for modern interiors, offering a lightweight yet durable solution for dividing spaces. Made from high-quality PVC foam, these partitions provide excellent insulation, soundproofing, and easy installation. They are ideal for both residential and commercial applications, combining aesthetics with functionality.":"Các sản phẩm Vách Ngăn của Pima được thiết kế cho nội thất hiện đại, mang lại giải pháp nhẹ nhưng bền để phân chia không gian. Được làm từ foam PVC chất lượng cao, các vách ngăn này cung cấp khả năng cách nhiệt, cách âm tuyệt vời và dễ dàng lắp đặt. Chúng rất lý tưởng cho cả ứng dụng dân dụng và thương mại, kết hợp giữa thẩm mỹ và chức năng.",
        "Pima produces high-quality doors designed for durability and style. Our doors combine aesthetic appeal with robust construction, making them ideal for both residential and commercial applications. Crafted with precision, Pima’s doors offer reliable performance and enhance any space with their elegant design.":"Pima sản xuất các loại cửa chất lượng cao, kết hợp giữa độ bền và phong cách. Cửa của chúng tôi có thiết kế đẹp mắt và cấu trúc chắc chắn, phù hợp cho cả ứng dụng dân dụng và thương mại. Được chế tạo chính xác, cửa của Pima mang đến hiệu suất đáng tin cậy và làm nổi bật không gian với thiết kế thanh lịch.",
        "Door":"Cửa",
        "Pima offers a range of Decorative Wall Panels, available in both Fluted and Flat designs. These panels combine aesthetic appeal with functionality, enhancing interior and exterior spaces with stylish and versatile solutions. Ideal for creating distinctive visual effects, Pima’s wall panels are crafted for durability and easy installation.":"Pima cung cấp các Panel Tường Trang Trí với thiết kế Gợn Sóng và Bằng Phẳng. Những panel này kết hợp vẻ đẹp thẩm mỹ với tính năng sử dụng, làm nổi bật không gian nội thất và ngoại thất với các giải pháp phong cách và đa dụng. Thích hợp để tạo hiệu ứng hình ảnh đặc biệt, các panel tường của Pima được chế tạo với độ bền cao và dễ dàng lắp đặt.",
        "Decorative (Fluted & Flat) Wall Panel":"Panel Tường Trang Trí (Gợn Sóng & Bằng Phẳng)",
        "View Details":"Xem chi tiết",
        "PVC Foam Sheet & Laminated Foam Sheet":"Tấm Foam PVC & Tấm Foam Laminated",
        "Pima specializes in producing durable, lightweight PVC Foam Sheets and Laminated Foam Sheets, perfect for signage, construction, and decorative uses. Known for quality and versatility, Pima delivers materials that combine strength and style.":"Pima chuyên sản xuất các tấm Foam PVC và tấm Foam Laminated bền, nhẹ, lý tưởng cho các ứng dụng như biển quảng cáo, xây dựng và trang trí. Được biết đến với chất lượng và sự đa dạng, Pima cung cấp các vật liệu kết hợp giữa sức mạnh và phong cách.",
        "Pima Introduction":"Giới thiệu Pima",
        "Wall panel, Wall Fluted Panel":"Tấm vách ngăn, Tấm ốp lam sóng",
        "Composite Door":"Cửa Composite",
        "Marble":"Cẩm Thạch",
        "Wall Panel, Fluted Panel, Cabinet, Composite Door":"Tấm vách ngăn, Tấm ốp lam sóng, Tủ bếp, Cửa composite",
        "Ceiling Panel 3D":"Tấm ốp trần 3D",
        "Ceiling Panel 2D":"Tấm ốp trần 2D",
        "Foam Board, Cabinet Profile & accessories, Ceiling panel, immitation Marble Board, Wall panel, Wall Fluted Panel, Decor Edge Banding, Composite Door":
        "Tấm ván nhựa, Phôi thanh cửa, Tấm trần nhựa, Tấm Cẩm Thạch, Tấm vách ngăn, Tấm ốp lam sóng, Nẹp trang trí, cửa composite",
        "Site by our in-house development team":"Hệ thống quản lý website này được tạo và phát triển bởi đội ngũ lập trình nội bộ của công ty",
        "Head office":"Trụ sở chính",
        "Connect with us":"Kết nối với chúng tôi",
        "Main factory of the company":"Nhà máy chính của công ty",
        "Person in charge":"Người phụ trách",
        "Company name":"Tên công ty",
        "Gallery":"Trưng bày",
        "Catalogs":"Catalogs",
        "Phone number updated successfully!":"Số điện thoại mới đã được cập nhật thành công!",
        "New Phone":"Số điện thoại mới",
        "Update Phone":"Cập nhật số điện thoại",
        "Current Phone":"Số điện thoại hiện tại",
        "Login wrong 5 times, your account has been locked, please contact the company to resolve.":"",
        "Phone Sign in incorrect":"Phone đăng nhập không đúng",
        "User does not exist!":"Không tìm thấy người dùng! (Không tim thấy số điện thoại tương ứng)",
        "Old password is incorrect!":"Mật khẩu cũ không đúng!",
        "Passwords do not match!":"Mật khẩu mới không nhất quán!",
        "Password updated successfully!":"Mật khẩu cập nhật thành công!",
        "Password":"Mật khẩu",
        "Phone":"Điện thoại",
        "Change password failed":"Đổi mật khẩu thất bại",
        "Change password successfully":"Đổi mật khẩu thành công",
        Contact: "Liên hệ",
        Language: "Ngôn ngữ",
        Products: "Sản Phẩm",
        News: "Tin tức",
        Cart: "Giỏ hàng",
        Settings: "Cài đặt",
        "Sign In": "Đăng nhập",
        "Sign Out": "Đăng xuất",
        "Change Phone": "Đổi Số điện thoại",
        "Orders": "Đơn hàng",
        "Old password": "Mật khẩu cũ",
        "New password": "Mật khẩu mới",
        "Password confirmed": "Mật khẩu xác nhận",
        "Password must have at least 8 characters, maximum 30 characters, including lowercase, uppercase, special character, and number.":"Mật khẩu phải có ít nhất 8 ký tự, tối đa 30 ký tự, bao gồm chữ thường, chữ hoa, ký tự đặc biệt và số.",
        "New password is required":"Phải nhập mật khẩu mới",
        "Old password is required":"Phải nhập mật khẩu cũ",
        "Passwords must match":"Mật khẩu cũ và mới phải giống nhau",
        "Please retype your password to confirm":"Vui lòng nhập lại mật khẩu để xác nhận",
        "Change Password": "Đổi mật khẩu",
        "Submit":"Gửi",
        "Reset":"Xóa",
        "Your password is incorrect. Sign in failed 1 times, if you sign in failed 5 times, your account will be locked":"Mật khẩu của bạn bị sai. Đăng nhập sai 1 lần, sai 5 lần thì tài khoản sẽ bị khóa",
        "Your password is incorrect. Sign in failed 2 times, if you sign in failed 5 times, your account will be locked":"Mật khẩu của bạn bị sai. Đăng nhập sai 2 lần, sai 5 lần thì tài khoản sẽ bị khóa",
        "Your password is incorrect. Sign in failed 3 times, if you sign in failed 5 times, your account will be locked":"Mật khẩu của bạn bị sai. Đăng nhập sai 3 lần, sai 5 lần thì tài khoản sẽ bị khóa",
        "Your password is incorrect. Sign in failed 4 times, if you sign in failed 5 times, your account will be locked":"Mật khẩu của bạn bị sai. Đăng nhập sai 4 lần, sai 5 lần thì tài khoản sẽ bị khóa",
        "Your account has been locked, please contact the company to recover it.":"Tài khoản của bạn đã bị lock, bạn vui lòng liên hệ với công ty để phục hồi"

      },
    },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // Default language
  fallbackLng: "vi", // Fallback language if the selected one is not available
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
