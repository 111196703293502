import { UpdatePasswordDto } from '../types/UpdatePasswordDto';
import { UpdatePhoneDto } from '../types/UpdatePhone';
import api from './api';

export const changePassword = (data: UpdatePasswordDto)  => {
    const url = "/user/updatepassword";
    return api.put(url, data).then(res => res.data);
}
export const updatePhone = (data: UpdatePhoneDto)  => {
    const url = "/user/updatephone";
    return api.put(url, data).then(res => res);
}
