import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { observer } from "mobx-react-lite";
import { useAppStore } from '../../stores/AppStore';

interface MenuItemDropDownProps {
    title: string;
    dropdownItems: { label: string, icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> }[]; // Each item can have its own icon
    iconFront?: boolean; // Optional prop to position icon in front
    iconBack?: boolean;  // Optional prop to position icon behind
}

export const MenuItemDropDown: React.FC<MenuItemDropDownProps> = observer(({ title, dropdownItems, iconFront, iconBack }) => {

        const { authStore } = useAppStore();
        const lang = authStore.language || 'English';

        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);
    
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = (label: string) => {
            setAnchorEl(null);
            if (typeof label !== 'string') { // ne1u ko phải là string thì ko cho chạy tiếp
                return;
            }
            switch (label) {
                case 'Vietnam':
                    i18n.changeLanguage('Vietnam');
                    authStore.setLanguage('Vietnam');
                    break;
                case 'English':
                    i18n.changeLanguage('English');
                    authStore.setLanguage('English');
                    break;
    
                default:
                    break;
            }
        };
        const { i18n } = useTranslation();
        // console.log('stateLanguage: ', stateLanguage);
        // console.log('lang: ', lang);
        return (
            <div>
                <Button
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    style={{
                        fontFamily: 'Roboto-Regular',
                        color: 'black',
                        fontSize: '5rem',
                        padding: '0px',
                        margin: '0px',
                    }}
                >
                    {lang === 'Vietnam' ? 
                        dropdownItems[0].icon && React.createElement(dropdownItems[0].icon, { style: { width: '33px', height: '33px', marginRight: '8px' } })
                        :
                        dropdownItems[1].icon && React.createElement(dropdownItems[1].icon, { style: { width: '33px', height: '33px', marginRight: '8px' } })
                    }
    
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {dropdownItems.map((item, index) => {
                        // console.log('item.label: ', item.label); // Move console.log inside the function block

                        return (
                            <MenuItem
                                key={index}
                                onClick={() => handleClose(item.label)}
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                {item.icon && iconFront && (
                                    <item.icon style={{ width: '24px', height: '24px', marginRight: '8px' }} />
                                )}
                                {item.label}
                                {item.icon && iconBack && (
                                    <item.icon style={{ width: '24px', height: '24px', marginLeft: '8px' }} />
                                )}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </div>
        );
    })