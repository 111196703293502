import { makeAutoObservable } from "mobx";
import { makePersistable, isPersisting, stopPersisting } from "mobx-persist-store";
import { SignInRequest } from "../types/SignInRequest";
import { login, logout } from "../services";
import { runInAction } from "mobx";
/*
When to use runInAction
You should use runInAction in the following cases:

Multiple State Changes at Once: When you need to update multiple observable properties within a single function.
Asynchronous Actions: When you have asynchronous 
*/
interface User {
    name: string;
    employeeId: string;
    email: string;
    department: string;
    phone: string;
    role: string;
    userId: string;
}

export class AuthStore {
    token: string = "";
    user: User | undefined = undefined;
    language: string = "en";

    constructor() {
        makeAutoObservable(this);

        // Only stop persisting if already persisting, to avoid memory leaks
        if (isPersisting(this)) {
            stopPersisting(this);
        }

        // Persist only if not already persisted
        makePersistable(this, {
            name: 'AuthStore',
            properties: ['token', 'user', 'language'],
            storage: window.localStorage
        });
    }
    // The isAuthenticated getter checks whether the token property exists and has a value. If there’s a token (indicating the user is authenticated), it returns true. If there's no token, it returns false.
    get isAuthenticated() {
        return !!this.token;
    }

    setLanguage(lang: string) {
        this.language = lang;
    }

    async storeLogin(body: SignInRequest) {
        try {
            const obj = await login(body);
            if (obj.status === "succeed") {
                this.token = obj.access_token;
            }
            return obj;
        } catch (err) {
            console.log('err:', err);
            throw err; //nếu ko có dòng này nó lại tưởng là onSuccess
        }
    }

    async storeSaveUserToken(response: any) {
        this.token = response?.access_token;
        this.user = {
            name: response.user.name,
            employeeId: response.user.employeeId,
            email: response.user.email,
            department: response.user.department,
            phone: response.user.phone,
            role: response.user.role,
            userId: response.user.userId,
        };
    }

    updatePhone(newPhone: string) {
        if (this.user) {
            runInAction(() => {
                this.user!.phone = newPhone;  // Update the phone field
            });
        }
    }

    storeLogout = async () => {
        try {
            const phone = this.user?.phone; // Optional chaining to safely access phone
            if (!phone) {
                throw new Error("User phone is undefined");
            }
    
            const response = await logout(phone);
            if (response.status === 200) {
                // Use runInAction to modify observable properties
                runInAction(() => {
                    this.token = "";
                    this.user = undefined;
                });
            }
        } catch (err) {
            console.log('err:', err);
        }
    };
    
}
