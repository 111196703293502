import React from 'react';
import styled from 'styled-components';

interface DividerProps {
    width: number; // width in percentage
}

const StyledDivider = styled.hr<DividerProps>`
    border: none;
    border-top: 1px solid #ececec; // You can adjust the color and thickness here
    width: ${props => props.width}%;
    margin: 0 auto; // Adjust margins as needed
`;

const Divider: React.FC<DividerProps> = ({ width }) => {
    return <StyledDivider width={width} />;
};

export default Divider;
