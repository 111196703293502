import clsx from 'clsx';
import styles from './ProductGroup.module.scss';
import { observer } from 'mobx-react-lite';
import { CompositeInteriorDoor_icon, DecorativeFlutedFlatWallPanel_icon, Moulding_icon, PVCFoamSheetLaminatedFoamSheet_icon, TrimSheet_icon, WallPartition_icon } from '../../assets/images';
// import wall2 from '../../assets/images/PVC Foam Sheet & Laminated Foam Sheet.png';
import { useTranslation } from 'react-i18next';
import AnimatedSection from '../../components/common/AnimatedSection/AnimatedSection';
import { useAppStore } from '../../stores/AppStore';
import { useNavigate } from 'react-router-dom';
const ProductGroup = observer(() => {
    const { productStore } = useAppStore();
    const { t } = useTranslation();
    const navigate = useNavigate(); 

    const selectProductGroup = (id: number, name: string) => {
    
        productStore.updateProductGroupId(id);
        productStore.updateProductGroupName(name);
        navigate(`/product/${id}`, { replace: true });
    
    }
    return (
        <div className={clsx(styles.ProductGroup)}>
            <div className={clsx(styles.rowIcon)}>
                <div className={clsx(styles.box)}  onClick={()=>selectProductGroup(1, 'PVC Foam Sheet & Laminated Foam Sheet')}>
                    <div className={clsx(styles.icon)}>
                        <img src={PVCFoamSheetLaminatedFoamSheet_icon} className={clsx(styles.image)} alt="walpaper" />
                    </div>
                    <p className={clsx(styles.name)}>Foam Sheet</p>
                </div>
                <div className={clsx(styles.box)}  onClick={()=>selectProductGroup(2, 'Decorative (Fluted & Flat) Wall Panel')}>
                    <div className={clsx(styles.icon)}>
                        <img src={DecorativeFlutedFlatWallPanel_icon} className={clsx(styles.image)} alt="walpaper" />
                    </div>
                    <p className={clsx(styles.name)}>Wall Panel</p>
                </div>
                <div className={clsx(styles.box)}  onClick={()=>selectProductGroup(3,'Door')}>
                    <div className={clsx(styles.icon)}>
                        <img src={CompositeInteriorDoor_icon} className={clsx(styles.image, styles.door)} alt="walpaper" />
                    </div>
                    <p className={clsx(styles.name)}>Door</p>
                </div>
                <div className={clsx(styles.box)}  onClick={()=>selectProductGroup(4, 'Wall Partition')}>
                    <div className={clsx(styles.icon)}>
                        <img src={WallPartition_icon} className={clsx(styles.image)} alt="walpaper" />
                    </div>
                    <p className={clsx(styles.name)}>Wall Partition</p>
                </div>
                <div className={clsx(styles.box)}  onClick={()=>selectProductGroup(5, 'Moulding')}>
                    <div className={clsx(styles.icon)}>
                        <img src={Moulding_icon} className={clsx(styles.image)} alt="walpaper" />
                    </div>
                    <p className={clsx(styles.name)}>Moulding</p>
                </div>
                <div className={clsx(styles.box)}  onClick={()=>selectProductGroup(6, 'Trim & Sheet')}>
                    <div className={clsx(styles.icon)}>
                        <img src={TrimSheet_icon} className={clsx(styles.image)} alt="walpaper" />
                    </div>
                    <p className={clsx(styles.name)}>Trim & Sheet</p>
                </div>
            </div>
            <AnimatedSection className={clsx(styles.structure, styles.foamSheet)} >
                <div className={clsx(styles.bgColor)}>
                    <div className={clsx(styles.sample)}>
                        <p className={clsx(styles.title)}>{t('PVC Foam Sheet & Laminated Foam Sheet')}</p>
                        <p className={clsx(styles.intro)}>
                            {t('Pima specializes in producing durable, lightweight PVC Foam Sheets and Laminated Foam Sheets, perfect for signage, construction, and decorative uses. Known for quality and versatility, Pima delivers materials that combine strength and style.')}
                        </p>
                        <p className={clsx(styles.view)}  onClick={()=>selectProductGroup(1, 'PVC Foam Sheet & Laminated Foam Sheet')}>{t('View Details')}</p>
                    </div>
                </div>
            </AnimatedSection>
            <AnimatedSection className={clsx(styles.structure, styles.wallPanel)}>
                <div className={clsx(styles.bgColor)}>
                    <div className={clsx(styles.sample)}>
                        <p className={clsx(styles.title)}>{t('Decorative (Fluted & Flat) Wall Panel')}</p>
                        <p className={clsx(styles.intro)}>
                            {t('Pima offers a range of Decorative Wall Panels, available in both Fluted and Flat designs. These panels combine aesthetic appeal with functionality, enhancing interior and exterior spaces with stylish and versatile solutions. Ideal for creating distinctive visual effects, Pima’s wall panels are crafted for durability and easy installation.')}
                        </p>
                        <p className={clsx(styles.view)}  onClick={()=>selectProductGroup(2, 'Decorative (Fluted & Flat) Wall Panel')}>{t('View Details')}</p>
                    </div>
                </div>
            </AnimatedSection>
            <AnimatedSection className={clsx(styles.structure, styles.doorWall)}>
                <div className={clsx(styles.bgColor)}>
                    <div className={clsx(styles.sample)}>
                        <p className={clsx(styles.title)}>{t('Door')}</p>
                        <p className={clsx(styles.intro)}>
                            {t('Pima produces high-quality doors designed for durability and style. Our doors combine aesthetic appeal with robust construction, making them ideal for both residential and commercial applications. Crafted with precision, Pima’s doors offer reliable performance and enhance any space with their elegant design.')}
                        </p>
                        <p className={clsx(styles.view)}  onClick={()=>selectProductGroup(3,'Door')}>{t('View Details')}</p>
                    </div>
                </div>
            </AnimatedSection>
            <AnimatedSection className={clsx(styles.structure, styles.wallPatition)}>
                <div className={clsx(styles.bgColor)}>
                    <div className={clsx(styles.sample)}>
                        <p className={clsx(styles.title)}>{t('Wall Partition')}</p>
                        <p className={clsx(styles.intro)}>
                            {t('Pima\'s Wall Partition products are designed for modern interiors, offering a lightweight yet durable solution for dividing spaces. Made from high-quality PVC foam, these partitions provide excellent insulation, soundproofing, and easy installation. They are ideal for both residential and commercial applications, combining aesthetics with functionality.')}
                        </p>
                        <p className={clsx(styles.view)}  onClick={()=>selectProductGroup(4, 'Wall Partition')}>{t('View Details')}</p>
                    </div>
                </div>
            </AnimatedSection>
            <AnimatedSection className={clsx(styles.structure, styles.moulding)}>
                <div className={clsx(styles.bgColor)}>
                    <div className={clsx(styles.sample)}>
                        <p className={clsx(styles.title)}>{t('Moulding')}</p>
                        <p className={clsx(styles.intro)}>
                            {t('Pima\'s Moulding Decoration products offer a stylish and elegant solution for enhancing interior spaces. Made from high-quality PVC materials, they are lightweight, durable, and easy to install. These decorative mouldings add a refined finish to walls and ceilings, suitable for both residential and commercial environments, creating a sophisticated and classic look.')}
                        </p>
                        <p className={clsx(styles.view)}  onClick={()=>selectProductGroup(5, 'Moulding')}>{t('View Details')}</p>
                    </div>
                </div>
            </AnimatedSection>
            <AnimatedSection className={clsx(styles.structure, styles.trimSheet)}>
                <div className={clsx(styles.bgColor)}>
                    <div className={clsx(styles.sample)}>
                        <p className={clsx(styles.title)}>{t('Trim & Sheet')}</p>
                        <p className={clsx(styles.intro)}>
                            {t('Pima\'s Trim & Sheet Decoration products are designed to add a polished and professional finish to various surfaces. These trims and sheets, made from high-quality PVC materials, are lightweight, durable, and easy to install. Ideal for enhancing the appearance of walls, floors, and ceilings, they offer both decorative and functional benefits, making them suitable for residential and commercial projects.')}
                        </p>
                        <p className={clsx(styles.view)}  onClick={()=>selectProductGroup(6, 'Trim & Sheet')}>{t('View Details')}</p>
                    </div>
                </div>
            </AnimatedSection>
           
        </div>
    );
});

export default ProductGroup;
