import React from 'react';
import { useInView } from 'react-intersection-observer';
import clsx from 'clsx';
import styles from './AnimatedSection.module.scss'; // Replace with your actual styles module

interface AnimatedSectionProps {
  children: React.ReactNode;
  className: string;
}

const AnimatedSection: React.FC<AnimatedSectionProps> = ({ children, className }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation will only happen once
    threshold: 0.1,   // Trigger animation when 10% of the element is in view
  });

  return (
    <div
      ref={ref}
      className={clsx(className, {
        [styles.inView]: inView,  // Apply CSS class when in view
        [styles.outOfView]: !inView, // Apply CSS class when out of view
      })}
    >
      {children}
    </div>
  );
};

export default AnimatedSection;
