// recft_clsx_module_scss
import { useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from "react-router-dom";
import styles from './SignIn.module.scss'
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { observer } from "mobx-react-lite";
import { Controller, useForm } from "react-hook-form";
import { SignInRequest } from '../../types/SignInRequest';
import PhoneIcon from '@mui/icons-material/Phone';
import ErrorIcon from '@mui/icons-material/Error';
import Btn from '../../components/common/Btn';
import PasswordIcon from '@mui/icons-material/Password';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useMutation } from "@tanstack/react-query";

// import { useMutation } from 'react-query';
import { useAppStore } from '../../stores/AppStore';
import { validatePassword, validatePhoneNumberBoolean } from '../../utils/validation';
import { login } from '../../services';
import { useTranslation } from 'react-i18next';

const schema = yup
   .object({
      phone: yup
         .string()
         .required("Phone là bắt buộc")
         .test("isPhone", "Phone không hợp lệ", validatePhoneNumberBoolean)
      ,
      password: yup
         .string()
         .required("Mật khẩu là bắt buộc")
         .test("isPassword", "Mật khẩu không hợp lệ", validatePassword)
      ,
   })
   .required();

const SignIn = observer(() => {
   const { authStore } = useAppStore();
   const { t } = useTranslation();

   const navigate = useNavigate(); //hook dùng để chuyển trang web

   const { control, handleSubmit, reset } = useForm<SignInRequest>({
      resolver: yupResolver(schema),
      defaultValues: {
         phone: "",
         password: "",
      },
      mode: 'onChange', // kiểm tra validate mỗi letter khi type
   });

   const mutation = useMutation({
      mutationFn: login,
      onSuccess: (response: any) => {
         // console.log('response: ', response);
         if(response.status === "success login & token saved"){
            authStore.storeSaveUserToken(response);
            navigate("/product/", { replace: true });
         }
         else {
            alert(response.message);
         }
      },
      onError: (error: any) => {
         console.log('error: ', error);
         alert(t(error.response.data.message));
      }
   });
   const onSubmit = (data: SignInRequest) => {
      // console.log('data: ', data);
      mutation.mutate(data);
   };
   const [stateBoolShowPass, setStateBoolShowPass] = useState(false); // boolean
   const onreset = () => {
      reset();
   }
   return (
      <div className={clsx(styles.component_SignIn)}>
         <div className={clsx(styles.container)}>
            <div className={clsx(styles.formLogin)}>
               <p className={clsx(styles.title)}>{t('Sign In')}</p>

               <form onSubmit={handleSubmit(onSubmit)}>

                  <Controller control={control} name="phone" rules={{ required: true, }}
                     render={({ field, fieldState }) => (
                        <div className={clsx(styles.boxInput)}>
                           <div className={clsx(styles.row)}>
                              <p className={clsx(styles.key)}>{t('Phone')}: </p>
                              <div className={clsx(styles.boxValue)}>
                                 <PhoneIcon/>
                                 <input type='text' name="phone" value={field.value} 
                                    onChange={(textValue) => field?.onChange(textValue || "")}
                                    placeholder='Phone...' />
                                 <span></span>
                              </div>
                           </div>
                           {
                           fieldState?.error?.message &&
                           <div className={clsx(styles.rowError)}>
                              <p className={clsx(styles.space)}></p>
                              <div className={clsx(styles.boxValue)}>
                                 <ErrorIcon className={clsx(styles.icon)}/>
                                 <p className={clsx(styles.error)}>{fieldState?.error?.message}</p>
                                 <p></p>
                              </div>
                           </div>
                           }
                        </div>
                     )}
                  /> 
                  <Controller control={control} name="password" rules={{ required: true, }}
                     render={({ field, fieldState }) => (
                        <div className={clsx(styles.boxInput)}>
                           <div className={clsx(styles.row)}>
                              <p className={clsx(styles.key)}>{t("Password")}: </p>
                              <div className={clsx(styles.boxValue)}>
                                 <PasswordIcon className={clsx(styles.icon)}/>
                                 <input type= {stateBoolShowPass ? 'text' : 'password'} name="password" value={field.value} 
                                    onChange={(textValue) => field?.onChange(textValue || "")}
                                    placeholder='password...' />
                                 {  stateBoolShowPass === true ?
                                    <VisibilityOffIcon className={clsx(styles.eye)} onClick={()=>setStateBoolShowPass(false)}/>
                                    :
                                    <VisibilityIcon className={clsx(styles.eye)} onClick={()=>setStateBoolShowPass(true)}/>
                                 }
                              </div>
                           </div>
                           {
                           fieldState?.error?.message &&
                           <div className={clsx(styles.rowError)}>
                              <p className={clsx(styles.space)}></p>
                              <div className={clsx(styles.boxValue)}>
                                 <ErrorIcon className={clsx(styles.icon)}/>
                                 <p className={clsx(styles.error)}>{fieldState?.error?.message}</p>
                                 <p></p>
                              </div>
                           </div>
                           }
                        </div>

                     )}
                  /> 

                  <div className={clsx(styles.btnWrapper)}>
                     <span onClick={() => handleSubmit(onSubmit)}>
                     <Btn 
                        colorText="white" 
                        colorBg="warning" 
                        hoverColorBg="warning" 
                        text={t('Sign In')} 
                     />
                     </span>
                     <span onClick={onreset}>
                     <Btn 
                        colorText="white" 
                        colorBg="secondary" 
                        hoverColorBg="secondary" 
                        text={t('Reset')} 
                     />
                     </span>
                  </div>
               </form>

            </div>
         </div>
               {/* {
                  stateSignInFail &&
                  <p style={{ color: 'red' }}>Fail sign in !. Email or password is incorrect, or your account has been blocked. Please try again</p>
               } */}
      </div>
   )
})
export default SignIn;
