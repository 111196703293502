import { makeAutoObservable } from "mobx";
import { makePersistable, isPersisting, stopPersisting } from "mobx-persist-store";


export class GalleryStore {
    gallerySelected: string = "Moulding";

    constructor() {
        makeAutoObservable(this);

        // Only stop persisting if already persisting, to avoid memory leaks
        if (isPersisting(this)) {
            stopPersisting(this);
        }

        // Persist only if not already persisted
        makePersistable(this, {
            name: 'GalleryStore',
            properties: ['gallerySelected'],
            storage: window.localStorage
        });
    }

    updateGallerySelected(gallerySelected: string) {
        this.gallerySelected = gallerySelected;
    }
}
