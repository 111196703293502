import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import { observer } from "mobx-react-lite";
import { useAppStore } from '../stores/AppStore';

// Direct imports (eager loading)
import { Spinner } from '../assets/images';
import { Catalog, Contact, Gallery, Product, ProductGroup, ProductVariant, SignIn } from '../pages';
import { CatalogBook } from '../pages/Catalog/CatalogBook';

// Lazy loading for other components
const ChangePassword = React.lazy(() => import('../pages/ChangePassword/ChangePassword'));
const UpdatePhone = React.lazy(() => import('../pages/UpdatePhone/UpdatePhone'));

export const AppRouter: React.FC = observer(() => {
    const { authStore } = useAppStore();
    const isAuthenticated = authStore.isAuthenticated;
    const userRole = authStore.user?.role || '';

    const LoadingSpinner = () => (
        <img src={Spinner} style={{ margin: '10rem auto', display: 'block' }} alt="loader" />
    );

    return (
        <Routes>
            <Route element={<PublicRoute />}>
                <Route path="/" element={<ProductGroup />} /> 
                <Route path="/product/:id" element={<Product />} /> 
                <Route path="/productgroup" element={<ProductGroup />} /> 
                <Route path="/productvariant/:id" element={<ProductVariant />} /> 
                <Route path="/signin" element={<SignIn />} /> 
                <Route path="/contact" element={<Contact />} /> 
                <Route path="/catalog" element={<Catalog />} /> 
                <Route path="/catalog/:pdfName" element={<CatalogBook />} /> 
                <Route path="/gallery" element={<Gallery />} /> 
                <Route path="/gallery/:galleryType" element={<Gallery />} /> 
                <Route path="/productgroup/:id" element={<Product />} /> 
                <Route path="/productvariant/:id" element={<ProductVariant />} /> 
            </Route>

            <Route element={<PrivateRoute isAuthenticated={isAuthenticated} userRole={userRole} allowedRoles={['employee', 'admin']} />}>
                <Route path="/changepassword" element={<Suspense fallback={<LoadingSpinner />}><ChangePassword /></Suspense>} /> 
                <Route path="/updatephone" element={<Suspense fallback={<LoadingSpinner />}><UpdatePhone /></Suspense>} /> 

            </Route>

            {/* Catch-all route for 404 Not Found */}
            {/* <Route path="*" element={<NotFoundPage />} /> */}
        </Routes>
    );
});
