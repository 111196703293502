import React from 'react';
import { Outlet } from 'react-router-dom';
import { GuestMenuBar } from '../components/layout/GuestMenuBar/GuestMenuBar';
import { Footer } from '../components/layout/footer/Footer';
import BottomMenuBar from '../components/layout/BottomMenuBar/BottomMenuBar';

const PublicRoute: React.FC = () => {
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <GuestMenuBar />
            <Outlet /> {/* This will render the child route component */}
            <Footer/>
            <BottomMenuBar/>
        </div>
    );
};

export default PublicRoute;
