import React, { useEffect } from 'react';
import './App.css';
import { observer } from "mobx-react-lite";
import { useAppStore } from './stores/AppStore';
import { useTranslation } from 'react-i18next';
import { AppRouter } from './routes/AppRouter';


const App = observer(() => {
  const { authStore } = useAppStore();
  const { i18n } = useTranslation();
  const language = authStore.language;
  // chạy ở đầu project luôn, ko cần care sub component nào
  useEffect(() => {
    i18n.changeLanguage(language);
  },[language, i18n]);

  return (
      <div>
        <AppRouter/>
      </div>
  )
})
export default App;