import clsx from 'clsx';
import styles from './UpdatePhone.module.scss';
import { observer } from "mobx-react-lite";
import { useAppStore } from '../../stores/AppStore';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { validatePassword, validatePhoneNumberBoolean } from '../../utils/validation';
import Btn from '../../components/common/Btn';
import { useTranslation } from 'react-i18next';
import { useMutation } from "@tanstack/react-query";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import { updatePhone } from '../../services';
import { UpdatePhoneDto } from '../../types/UpdatePhone';



const UpdatePhone = observer(() => {
    const { authStore } = useAppStore();
    const { t } = useTranslation();
    // Phone validation
    const phoneValidation = Yup.string()
        .test('phone-validation', 'Phone number is not valid', (value) => {
            // Handle case where value might be undefined
            if (!value) return false;
            return validatePhoneNumberBoolean(value);
        })
        .required('Phone number is required');

    // Password validation
    const passwordValidation = Yup.string()
        .test(
            'password-validation',
            'Password must have at least 8 characters, maximum 30 characters, including lowercase, uppercase, special character, and number.',
            (value) => {
                // Handle case where value might be undefined
                if (!value) return false;
                return validatePassword(value);
            }
        )
        .required('New password is required');

    // Define your validation schema
    const validationSchema = Yup.object().shape({
        phoneNumber: phoneValidation,
        password: passwordValidation,
    });



    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onChange', // Validate on change
    });

    const mutation = useMutation({
        mutationFn: updatePhone,
        onSuccess: (response: any) => {
            // console.log('response: ', response);
            if (response.status === 200) {
                authStore.updatePhone(response.data.newPhone);
                alert(t(response.data.message));
                reset();
            }
            else {
                alert(t(response.message));
            }
        },
        onError: (error: any) => {
            console.error('Error during mutation:', error);
            alert(t(error.response.data.message));
        }
    });
    // console.log("authStore.user?.phone", authStore.user?.phone);
    const onSubmit = (data: any) => {
        // console.log('data: ', data);
        if (authStore.user?.phone === undefined) {
            // Handle the case where phone is undefined
            // alert("Bạn cần phải sign in lại. Xin cảm ơn")
            authStore.storeLogout();
            window.location.href = '/signin';
            return;
        }
        // Handle form submission
        const obj: UpdatePhoneDto = {
            password: data.password,
            oldPhone: authStore.user?.phone,
            newPhone: data.phoneNumber,
        }
        mutation.mutate(obj);

    };

    const onReset = () => {
        reset();
    };
    const [stateBoolShowPassOld, setStateBoolShowPassOld] = useState(false); // boolean
    const [stateBoolShowPassNew, setStateBoolShowPassNew] = useState(false); // boolean

    // askfjJHF7364&^^
    return (
        <div className={clsx(styles.UpdatePhone)}>
            <div className={clsx(styles.container)}>
                <p className={clsx(styles.title)}>{t('Update Phone')}</p>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={clsx(styles.rowHorizontal)}>
                        <label>{t('Current Phone')}: </label>
                        <p className={clsx(styles.phone)}>{authStore.user?.phone}</p>
                    
                    
                    </div>
                    {/* Password */}
                    <div className={clsx(styles.row)}>
                        <label>{t('Password')}: </label>
                        <div className={clsx(styles.inputWrapper)}>
                            <input
                                type={stateBoolShowPassOld ? 'text' : 'password'}
                                {...register('password')} // Changed to 'password'
                            />
                            {stateBoolShowPassOld ? (
                                <VisibilityOffIcon
                                    className={clsx(styles.eye)}
                                    onClick={() => setStateBoolShowPassOld(false)}
                                />
                            ) : (
                                <VisibilityIcon
                                    className={clsx(styles.eye)}
                                    onClick={() => setStateBoolShowPassOld(true)}
                                />
                            )}
                        </div>
                    </div>
                    <div className={clsx(styles.row, styles.error)}>
                        <span>{errors.password?.message}</span> {/* Display error message for password */}
                    </div>

                    {/* Phone */}
                    <div className={clsx(styles.row)}>
                        <label>{t('New Phone')}: </label>
                        <div className={clsx(styles.inputWrapper)}>
                            <input
                                type={stateBoolShowPassNew ? 'text' : 'password'}
                                {...register('phoneNumber')} // Changed to 'phone'
                            />
                            {stateBoolShowPassNew ? (
                                <VisibilityOffIcon
                                    className={clsx(styles.eye)}
                                    onClick={() => setStateBoolShowPassNew(false)}
                                />
                            ) : (
                                <VisibilityIcon
                                    className={clsx(styles.eye)}
                                    onClick={() => setStateBoolShowPassNew(true)}
                                />
                            )}
                        </div>
                    </div>
                    <div className={clsx(styles.row, styles.error)}>
                        <span>{errors.phoneNumber?.message}</span> {/* Display error message for phone */}
                    </div>

                    {/* Submit Button */}
                    <div className={clsx(styles.btnWrapper)}>
                        <span>
                            <Btn
                                colorText="white"
                                colorBg="warning"
                                hoverColorBg="warning"
                                text={t('Submit')}
                            />
                        </span>
                        <span onClick={onReset}>
                            <Btn
                                colorText="white"
                                colorBg="secondary"
                                hoverColorBg="secondary"
                                text={t('Reset')}
                            />
                        </span>
                    </div>
                </form>
            </div>

        </div>
    );
});

export default UpdatePhone;