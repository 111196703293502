import clsx from 'clsx';
import styles from './Contact.module.scss';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

const Contact = observer(() => {
    const { t } = useTranslation();

    return (
        <div className={clsx(styles.Contact)}>
            <div className={clsx(styles.left)}>
                <p className={clsx(styles.title)}>{t('Contact').toUpperCase()}</p>
                <div className={clsx(styles.row)}>
                    <span className={clsx(styles.key)}>{t('Company name')}:</span>
                    <span className={clsx(styles.value)}>Pima Co.,Ltd</span>
                </div>
                <div className={clsx(styles.row)}>
                    <span className={clsx(styles.key)}>{t('Person in charge')}:</span>
                    <span className={clsx(styles.value)}>Mr. Tuan Truong</span>
                </div>
                <div className={clsx(styles.row)}>
                    <span className={clsx(styles.key)}>Email:</span>
                    <a
                        className={clsx(styles.link)}
                        href="mailto:tuan.truong@pima.com.vn?subject=Subject&body=Dear%20Mr.Tuan,%0Ayour%20content%0A"
                        style={{
                            color: 'white',
                            textDecoration: 'none',
                            fontWeight: 'bold',
                            transition: 'color 0.3s ease'
                        }}
                        onMouseOver={(e) => (e.currentTarget.style.color = '#f2f700')}
                        onMouseOut={(e) => (e.currentTarget.style.color = 'white')}
                    >
                        tuan.truong@pima.com.vn
                    </a>
                </div>
                <div className={clsx(styles.row)}>
                    <span className={clsx(styles.key)}>Phone/WhatsApp:</span>
                    <span className={clsx(styles.value)}>+84 919 287 776</span>
                </div>
            </div>
            <div className={clsx(styles.right)}>
                <div className={clsx(styles.map)}>
                    <div className={clsx(styles.row)}>
                        <span className={clsx(styles.key)}>{t('Head office')}:</span>
                        <span className={clsx(styles.value)}>111/5B Pham Van Bach st, ward 15, Tan Binh District, HCM city, VietNam</span>
                    </div>
                    <div className={styles.mapContainer}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d16900.34949657176!2d106.63608!3d10.81731!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317529667ba9c62f%3A0xd2fe761ca2e08134!2sPlastic%20boards%2C%20plastic%20timber%20Pima!5e1!3m2!1sen!2sus!4v1726025356251!5m2!1sen!2sus"
                            title="Google Maps location for Plastic boards and plastic timber Pima"
                            allowFullScreen={false}
                            loading="lazy"
                        ></iframe>
                    </div>
                </div>
                <div className={clsx(styles.map)}>
                    <div className={clsx(styles.row)}>
                        <span className={clsx(styles.key)}>{t('Main factory of the company')}:</span>
                        <span className={clsx(styles.value)}>Lot F6, Road No. 10, Hai Son Duc Hoa Dong Industrial Park, Duc Hoa Dong Commune, Duc Hoa District, Long An Province, Vietnam</span>
                    </div>
                    <div className={styles.mapContainer}>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4224.886629280824!2d106.5053324!3d10.831548!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x310ad4ef4ca5fc1b%3A0xd25f867cea5a20a1!2zxJDhu6ljIEjDsmEgxJDDtG5nLCDEkOG7qWMgSMOyYSwgTG9uZyBBbg!5e1!3m2!1sen!2s!4v1726026886597!5m2!1sen!2s"
                            title="Google Maps location for factory"
                            allowFullScreen={false}
                            loading="lazy"
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Contact;
