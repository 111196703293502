import { SignInRequest } from '../types/SignInRequest';
import api from './api';

export const login = (data: SignInRequest)  => {
    const url = "/auth/login";
    return api.post(url, data).then(res => res.data);
}
export const checkToken = (token: string)  => {
    const url = "/auth/checkToken/"+token;
    return api.get(url).then(res => res.data);
}
export const logout = (phone: string)  => {
    const url = "/loginsuccess/deleteByPhone/"+phone;
    return api.delete(url).then(res => res);
}
