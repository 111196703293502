import React from 'react';
// import LoginIcon from '@mui/icons-material/Login';
// import LogoutIcon from '@mui/icons-material/Logout';
// import Settings from '@mui/icons-material/Settings';
// import PersonAddIcon from '@mui/icons-material/PersonAdd';
// import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
// import PasswordIcon from '@mui/icons-material/Password';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import VerticalAlignTopOutlinedIcon from '@mui/icons-material/VerticalAlignTopOutlined';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PushPinIcon from '@mui/icons-material/PushPin';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import DrawIcon from '@mui/icons-material/Draw';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import TableViewIcon from '@mui/icons-material/TableView';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CodeIcon from '@mui/icons-material/Code';

import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ChecklistIcon from '@mui/icons-material/Checklist';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
type IconSize = "small" | "medium" | "large" | "inherit";
export const GetIconByName = (iconName: string, inputWidth?: string, size?: IconSize, color?: string) => {

   switch (iconName) {
      case '':
         return ''
      case 'filter':
         return <FilterAltOutlinedIcon style={{ width: inputWidth}} fontSize={size || "medium"} />;
      case 'list':
         return <ChecklistIcon style={{ width: inputWidth}} fontSize={size || "medium"} />;
      case 'contact':
         return <ContactMailOutlinedIcon style={{ width: inputWidth}} fontSize={size || "medium"} />;
      case 'docs':
         return <DescriptionOutlinedIcon style={{ width: inputWidth}} fontSize={size || "medium"} />;
      case 'gallery':
         return <CollectionsOutlinedIcon style={{ width: inputWidth}} fontSize={size || "medium"} />;
      case 'category':
         return <CategoryOutlinedIcon style={{ width: inputWidth}} fontSize={size || "medium"} />;
      case 'CodeIcon':
         return <CodeIcon style={{ width: inputWidth, color: 'white' }} fontSize={size || "medium"} />;
      case 'YouTubeIcon':
         return <YouTubeIcon style={{ width: inputWidth, color: '#FF0000' }} fontSize={size || "medium"} />;
      case 'FacebookIcon':
         return <FacebookIcon style={{ width: inputWidth, color: '#1877F2' }} fontSize={size || "medium"} />;
      case 'AccountCircleIcon':
         return <AccountCircleIcon style={{ width: inputWidth, color: 'gray' }} fontSize={size || "medium"} />;
      case 'dropDown':
         return <ArrowDropDownIcon style={{ width: inputWidth }} fontSize={size || "medium"} />;
      case 'dropUp':
         return <ArrowDropUpIcon style={{ width: inputWidth }} fontSize={size || "medium"} />;
      case 'cloud_uploud_material':
         return <CloudUploadOutlinedIcon style={{ width: inputWidth }} fontSize={size || "medium"} />;
      case 'table':
         return <TableViewIcon style={{ width: inputWidth }} fontSize={size || "medium"} />;
      case 'verticalSplit':
         return <VerticalSplitIcon style={{ width: inputWidth }} fontSize={size || "medium"} />;
      case 'time':
         return <AccessTimeIcon style={{ width: inputWidth }} fontSize={size || "medium"} />;
      case 'subtract':
         return <RemoveIcon style={{ width: inputWidth }} fontSize={size || "medium"} />;
      case 'block':
         return <BlockOutlinedIcon style={{width:inputWidth}}/>
      case 'refresh':
         return <SyncOutlinedIcon style={{width:inputWidth}}/>
      case 'approve':
         return <CheckCircleOutlineOutlinedIcon style={{width:inputWidth}}/>
      case 'arrowRight':
         return <KeyboardArrowRightIcon fontSize="medium"/>
      case 'arrowDown':
         return <KeyboardArrowDownIcon fontSize="medium"/>
      
      case 'add':
         return <AddIcon style={{width:inputWidth == null ? '1.5rem':inputWidth}}/>
      
      case 'alignBottom':
         return <VerticalAlignBottomOutlinedIcon style={{width:'1.5rem'}}/>
      
      case 'alignTop':
         return <VerticalAlignTopOutlinedIcon style={{width:'1.5rem'}}/>
      
      case 'refresh2':
         return <RotateLeftOutlinedIcon style={{width:'1.5rem'}}/>
      case 'trash':
         return <DeleteForeverOutlinedIcon style={{width:inputWidth == null ? '1.5rem':inputWidth}} fontSize={size || "medium"}/>
      case 'copy':
         return <ContentCopyIcon style={{width:inputWidth}}/>
      case 'pin':
         return <PushPinIcon style={{width:inputWidth}}/>
      case 'submit':
         return <BackupOutlinedIcon style={{width: inputWidth}}/>
      case 'print':
         return <PrintOutlinedIcon style={{width: inputWidth}}/>
      case 'search':
         return <SearchIcon style={{width: inputWidth}}/>
      case 'close':
         return <CloseIcon style={{width: inputWidth}}/>
      case 'list2': 
         return <FormatListNumberedIcon style={{width: inputWidth}}/>
      case 'edit':
         return <DrawIcon style={{width: inputWidth}}/>
      case 'trash2':
         return <DeleteOutlineIcon style={{width: inputWidth}}/>
      
      default:
         return <React.Fragment/>
   }
}
