// src/types/GalleryImage.ts

// Enum for Image Categories
export enum ImageCategory {
  LivingRoom = 'Living Room',
  Kitchen = 'Kitchen',
  Bathroom = 'Bathroom',
  Office = 'Office',
  Bedroom = 'Bedroom',
  HallwaysandFoyers = 'Hallways and Foyers',
  DiningRoom = 'Dining Room',
  MediaRoom = 'Media Room',
  LaundryRoom = 'Laundry Room',
  Closet = 'Closet',
  Library = 'Library',
  Terrace = 'Terrace',
  ArtStudio = 'Art Studio',
  BilliardRoom = 'Billiard Room',
  Lounge = 'Lounge',
  Pool = 'Pool',
  WineRoom = 'Wine Room',
}
export enum GalleryType {
  Moulding = 'Moulding',
  Nucedar = 'Nucedar',
  Beadboard = 'Beadboard',

}

// Interface for an Image
export interface GalleryImage {
  id: number;
  url: string;
  href: string;
  category: ImageCategory;
  galleryType: GalleryType;
}

// Add this empty export to ensure the file is treated as a module
export {};
