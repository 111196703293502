/*
validatePassword: password must have at least 8 characters maximum 30 characters, including lowercase, uppercase, special character, number

validatePhoneNumberBoolean: validate a phone number with exactly 10 digits

validateName: validate name must have at least 4 characters maximum 70 characters, including a-z, A-Z

validateEmail: validate email

validateCCCD: validate CCCD 

validateUsername: validate username
*/

export function validateBirthday(dateString: string) {
    // Define a regular expression pattern to match the date format
    const pattern = /^(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-\d{4}$/;

    // Check if the date string matches the pattern
    if (!pattern.test(dateString)) {
        return false; // If the format is invalid, return false
    }

    // Split the date string into day, month, and year
    const [day, month, year] = dateString.split('-').map(Number);

    // Create a Date object with the given year, month (0-indexed), and day
    const date = new Date(year, month - 1, day);

    // Check if the date object represents a valid date
    // Also check if the year, month, and day match the input values
    return (
        date.getFullYear() === year &&
        date.getMonth() === month - 1 &&
        date.getDate() === day
    );
}


export function validatePassword(password: string | undefined) {
    /*
      Validate password must have at least 8 characters, maximum 30 characters, including lowercase, uppercase, special character, and number.
      */
    if (!password) {
        return false;
    }

    // Check if the password length is between 8 and 30 characters
    if (password.length < 8 || password.length > 30) {
        return false;
    }

    // Check if the password contains at least one lowercase letter
    if (!/[a-z]/.test(password)) {
        return false;
    }

    // Check if the password contains at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
        return false;
    }

    // Check if the password contains at least one special character
    // No need to escape the [ and ] inside the character class []
    if (!/[!@#$%^&*()_+{}:;<>,.?~\\/-]/.test(password)) {
        return false;
    }

    // Check if the password contains at least one number
    if (!/\d/.test(password)) {
        return false;
    }

    // If all checks pass, the password is valid
    return true;
}


export function validatePhoneNumberBoolean(phoneNumber: string) {
    // Regular expression to validate a phone number with exactly 10 digits
    const phoneRegex = /^\d{10}$/;

    // Test if the phone number matches the regex
    if (!phoneRegex.test(phoneNumber)) {
        return false; // Return false if the basic format is not met
    }
    return true;
    // // Extract the first three digits of the phone number
    // const firstThreeDigits = phoneNumber.substring(0, 3);

    // // Define lists of valid first three digits for each carrier
    // const prefixMap = {
    //     Viettel: [
    //         "086",
    //         "096",
    //         "097",
    //         "098",
    //         "039",
    //         "038",
    //         "037",
    //         "036",
    //         "035",
    //         "034",
    //         "033",
    //         "032",
    //     ],
    //     Vinaphone: ["091", "094", "088", "083", "084", "085", "081", "082"],
    //     Mobiphone: ["070", "079", "077", "076", "078", "089", "090", "093"],
    //     Vietnamobile: ["092", "052", "056", "058"],
    //     Gmobile: ["099", "059"],
    //     Itelecom: ["087"],
    // };
    // let result = false;
    // // Check if the first three digits belong to any of the specified lists
    // for (const [prefixes] of Object.entries(prefixMap)) {
    //     if (prefixes.includes(firstThreeDigits)) {
    //         result = true;
    //     }
    // }

    // result = false; // Phone number is not valid
    // console.log('result: ', result);
    // return result;
}


export function validateName(value: string): boolean {
    const isValid =
        value.length >= 4 && value.length <= 70 && /[a-zA-Z]/.test(value);
    return isValid;
}

export function validateEmail(email: string) {
    // Regular expression for stricter email validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
}

export function validateCCCD(inputString: string) {
    const arrMa = ["001", "001", "002", "004", "006", "008", "010", "011", "012", "014", "015", "017", "019", "019", "020", "022", "024", "025", "026", "027", "030", "031", "033", "034", "035", "036", "037", "038", "040", "042", "044", "045", "046", "048", "049", "051", "052", "054", "056", "058", "060", "062", "064", "064", "066", "067", "068", "070", "072", "074", "075", "077", "079", "080", "082", "083", "084", "086", "087", "089", "091", "092", "093", "095", "094", "096"]

    // Check if the input string contains exactly 12 digits
    if (!/^\d{12}$/.test(inputString)) {
        return false;
    }

    // Check if digit index 0 to 2 exists in arrMa
    const firstThreeDigits = inputString.slice(0, 3);
    if (!arrMa.includes(firstThreeDigits)) {
        return false;
    }

    // Check if digit index 3 is between 0 and 9
    const fourthDigit = inputString.charAt(3);
    if (!/^[0-9]$/.test(fourthDigit)) {
        return false;
    }

    // Check if digit index 4 to 5 are between 00 and 99
    const fifthAndSixthDigits = inputString.slice(4, 6);
    if (!/^[0-9]{2}$/.test(fifthAndSixthDigits)) {
        return false;
    }

    // If all conditions are met, return true
    return true;
}

export function validateUsername(username: string): boolean {
    // Check if the username is not empty
    if (!username) {
        return false;
    }

    // Check for allowed characters using a regular expression
    if (!/^[a-zA-Z0-9_]+$/.test(username)) {
        return false;
    }

    // Validation passed
    return true;
}